import { GeoJsonTypes } from "geojson";

export interface InRangeBaseModel {
  createdBy: string;
  createdAt: number;
  updatedAt: number;
  updatedBy?: string;
}

export interface SiteMPAN {
  importMPAN: string | null;
  exportMPAN: string | null;
}

export interface DnoData {
  name: string;
  annualAverageIntensity: number;
}

export interface Substation {
  success: boolean;
  generationHeadroomKva: number;
  name?: string;
}

export interface SiteOwnership {
  orgID: string;
  ownership: string;
  siteID?: string;
  name?: string;
  park?: string;
}

export interface CostInputsPv {
  enablingWorksCosts: number;
  wiringCosts: number;
  hardwareCostPerKWp: number;
  installationCostPerKWp: number;
  maintenanceCostPerKWp: number;
  insuranceCostPerKWp: number;
  flatAnnualCost: number;
  roofRentPerM2: number;
  roofRentBtm: number;
  roofRentExport: number;
  contingency: number;
  capitalisedCost: number;
  capitalisedInterest: number;
  replacementCapexYear: number;
  replacementCapexRate: number;
}

export interface BatterySpec {
  modelName?: string | null;
  modelNotes?: string;
  capacityMwh: number;
  powerMw: number;
  depthOfDischarge?: number;
  roundTripEfficiency?: number;
  warranty?: {
    periodYears?: number;
    periodCycles?: number;
    capacity?: number;
  };
  quotedCosts?: {
    count: number;
    price: number;
    currency: string;
    installationCost?: number;
  }[];
  hardwareCost?: number;
  count?: number;
}

export interface CostInputsBattery {
  batterySpec?: BatterySpec;
  installationCost: number;
}

export interface CostInputsInrange {
  epcCostPerKWp?: number;
  constructionMonths: number;
  equityFraction: number;
  interestRateConstruction: number;
  interestRateOperation: number;
  vatRate: number;
  corporateTaxRate: number;
  targetMinimumAnnualDSCR: number;
  targetMinimumDSCR: number;
}

export interface HalfHourlyDemand {
  source: string;
  metadata?: {
    latest_full_year_num_rows_customer_data: number;
    latest_full_year_num_rows: number;
    input_file?: string;
    date_of_analysis?: string;
    site_mapping?: {
      overrides?: {
        existing_panels?: number;
      };
    };
  };
}

export interface HalfHourlyGeneration {
  kwpPerSqm: number;
  roofUtilization: number;
  locationGhi: number;
  ghi: number;
  totalGenerationLossFactor: number;
}

// SdmMatchConfig interface
export interface SdmMatchConfig {
  // >> Buyer/seller
  buyerId: string;
  buyerName?: string;
  sellerId: string;
  sellerName?: string;

  // >> Match config
  tariff: number;
  volume: number;
  ppaContractType: string;
  ppaLength: number;
  ppaIndex: number;
  isWired: boolean;

  // >> Energy flow storage information
  energyFlowInputsFileVersionId?: string;

  // >> State to allow us to detect when a match curve is stale
  existingMatchesHash?: string;
  existingMatchesHashStale?: boolean;
}

export interface EnergyMatchConfig {
  tariff: number;
  ppaContractType: string;
  ppaLength: number;
  ppaType: string;
  ppaIndex: number;
}

export interface AggregateMatchedEnergy {
  sourceType: string;
  sinkType: string;
  energyFlow: number;
  energyMatchConfig: EnergyMatchConfig;
  sinkId?: string;
  sourceId?: string;
}

export interface AggregateEnergyFlow {
  demand: number;
  generation: number;
  batteryCharge: number;
  batteryDischarge: number;
  batteryChargeLoss: number;
  batteryMaxFinalCharge: number;
  behindMeter: number;
  behindMeterPV: number;
  behindMeterBattery: number;
  exported: number;
  exportedPV: number;
  exportedBattery: number;
  curtailed: number;
  networkImport: number;
  networkExport: number;
  gridImport: number;
  procurement: number;

  peakGrid: number;
  peakInRange: number;
  peakBehindMeter: number;

  offPeakGrid: number;
  offPeakInRange: number;
  offPeakBehindMeter: number;

  daylightDemand?: number;
  daylightGrid?: number;
  daylightInRange?: number;
  daylightBehindMeter?: number;

  tenantEnergyShare: number;

  maxExport: number;

  matchedEnergy: AggregateMatchedEnergy[];

  energyBalancePV: number;
  energyBalanceBatteryDischarge: number;
  energyBalanceNetworkImport: number[];
  energyBalanceDemand: number;
  energyBalanceBatteryCharge: number;
  energyBalanceNetworkExport: number[];
}

export interface EnergyFlowActualsMetadata {
  first_day: string; // Assuming the date is returned as a string
  last_day: string; // Assuming the date is returned as a string
}

export interface MonthlyEnergyFlowActuals {
  energyFlow: AggregateEnergyFlow;
  metadata?: EnergyFlowActualsMetadata;
}

export interface PeriodEnergyFlow {
  // >> Energy Sources
  generation: number[];
  // >> Energy Sinks
  demand: number[];
  // >> Battery
  batteryCharge: number[];
  batteryDischarge: number[];
  batteryFinalCharge: number[];
  // >> Energy matches
  behindMeterPV: number[];
  behindMeterBattery: number[];
  networkImport: number[];
  networkExport: number[];
  exportedPV: number[];
  exportedBattery: number[];
  // >> Energy matches: Excess energy
  curtailed: number[];
  gridImport: number[];
}

export interface ProjectCosts {
  totalCostPerKwp: number;
  initialCostPerKWp: number;

  total: number;
  initialInvestment: number;

  // Hardware costs
  pvHardware: number;
  batteryHardware: number;
  totalHardware: number;

  // Install costs
  pvInstallation: number;
  batteryInstallation: number;
  totalInstallation: number;

  // Ongoing costs
  maintenance: number;
  insurance: number;
  additionalCosts: number;
  maintenanceYearOne: number;
  insuranceYearOne: number;

  // Replacement costs
  pvReplacementCapex: number;

  // Battery costs
  batteryReplacementCapex: number;
}

export interface FinancialModelSavings {
  savings: number;
  lifetimeSavings: number;
}

export interface FinancialModelLease extends FinancialModelSavings {
  revenue: number;
  lifetimeRevenue: number;
}

export interface FinancialModelLicense extends FinancialModelLease {
  inrangeNetRevenue: number;
  irr?: number;
  paybackMonths?: number;
}

export interface LeaseLicenseInvestmentModels {
  lease: FinancialModelLease;
  license: FinancialModelLicense;
}

export interface FinancialModelScenarios {
  landlord: LeaseLicenseInvestmentModels;
  tenant: FinancialModelSavings;
  ownerOccupier: LeaseLicenseInvestmentModels;
}

export interface FinancialModelInRangeLease {
  netRevenue: number;
  irr?: number;
  paybackMonths?: number;
  minADSCR: number;
}

export interface FinancialModelInRangeScenarios {
  grossEnergyRevenue: number;
  installArbitrage: number;
  lease: FinancialModelInRangeLease;
}

export interface FinancialPerformanceSavings {
  savings: number;
}

export interface FinancialPerformance extends FinancialPerformanceSavings {
  revenue: number;
}

export interface FinancialPerformanceInvestmentModels {
  lease: FinancialPerformance;
  license: FinancialPerformance;
}

export interface FinancialPerformanceScenarios {
  landlord: FinancialPerformanceInvestmentModels;
  tenant: FinancialPerformanceSavings;
  ownerOccupier: FinancialPerformanceInvestmentModels;
}

export interface HubSpotSite {
  siteID: string;
  hubspotID: string;
  createdBy: string;
  hubspotUpdateTime?: number;
}

export interface SiteFile extends InRangeBaseModel {
  siteID: string;
  fileType: string;
  fileName: string;
  fileGuid: string;
  fileVersionId: string;
  date?: number;
  isLandlord?: boolean;
  description?: string;
  invoiceNumber?: string;
  invoiceAmount?: number;
  invoiceDueDate?: number;
  statementNumber?: string;
  statementAmount?: number;
  issueDate?: number;
}

export interface Panel {
  manufacturer: string;
  model: string;
  width: number;
  height: number;
  weight: number;
  pnom: number;
  warranty: {
    yearOneEfficiency: number;
    periodYears: number;
    endOfPeriodEfficiency: number;
  };
}

export interface Building extends InRangeBaseModel {
  id: string;
  siteID: string;
  geometry: {
    type: GeoJsonTypes;
    coordinates: string | number[][][];
  };
  surfaceAreaSqM: number;
  polygonType?: string;
}

export const getBuildingCoordinates = (building: Building): number[][][] => {
  let coordinates = building.geometry.coordinates;
  if (typeof building.geometry.coordinates === "string") {
    coordinates = JSON.parse(building.geometry.coordinates);
  }
  return coordinates as unknown as [number, number][][];
};

export interface Calculations {
  emissionsAvoided: { totalAvoidance: number };
}

type MakeOptionalExcept<T, K extends keyof T> = Partial<T> & Pick<T, K>;

// Type used for creating a site - all fields are optional except for id
export type NewSite = MakeOptionalExcept<Site, "id" | "name">;

// Type used for previewing a site - all fields are optional
export type PartialSite = Partial<Site>;

export interface Site extends InRangeBaseModel {
  id: string;

  // >> Basic attributes
  name: string;
  address: string;
  postcode: string;
  notes: string;
  latitude: number;
  longitude: number;
  mapZoom: number;
  isScenario: boolean;
  siteMPANs: SiteMPAN[];
  score?: number;

  // >> Location linked data
  countryCode: string;
  currencyCode: string;
  areaUnit: string;
  dno?: DnoData;
  substationData?: Substation;

  // >> Buildings
  buildings: Building[];
  totalBuildingArea: number;

  // >> Ownership
  siteOwnerships: SiteOwnership[];

  // >> Demand
  demandCoefficientKWhPerM2: number;
  buildingProfile: string;
  profileShape: string;
  profileDays: string;
  hhDemandFileVersionId: string;
  hhDemandFileDescription: string;
  tenantAnnualDemandKwh: number;

  // >> Generation
  installedCapacity: number;
  generationLossFactors: Record<string, number>;
  pvPanelName: string;
  pvDesignSystemLastUpdatedAt?: number;
  pvDesignSystemLastUpdatedBy?: string;
  annualGenerationLoss: number;
  pvInverterBrand: string;
  costInputsPv: CostInputsPv;
  pvPanelCount: number;
  pvPanel: Panel;
  pvCurve: string;
  pvForecastProbability: string;
  overrideGHI?: number;
  pvYoyForecastVariability: number;

  // >> Battery
  batteryLastPriceInputs?: string;
  costInputsBattery: CostInputsBattery;

  // >> Energy flow inputs
  exportLimit?: number; // Units: kVA
  halfHourlyDemand: HalfHourlyDemand;
  halfHourlyGeneration: HalfHourlyGeneration;
  sdmInputsDataHash?: string;
  batteryEnergyFlowPayloadHash?: string | null;
  batteryEnergyFlowFileVersionId?: string | null;
  sdmMatches: SdmMatchConfig[];

  // >> Energy flow outputs
  energyFlowAnnual: AggregateEnergyFlow;
  energyFlowMonthly: {
    [month: number]: AggregateEnergyFlow;
  };
  monthlyTenantEnergyShare: number[];
  energyFlowSummerDay: PeriodEnergyFlow;
  energyFlowWinterDay: PeriodEnergyFlow;

  // >> Operational attributes
  commerciallyOperationalDateEpochSeconds?: number | null;
  commerciallyOperationalMonths: number;
  operationalStatus: string;

  // >> Tariffs
  marketTariff: number;
  tenantTariff: number;
  // inrangeExportTariff is a bad name, this is actually the "Spill export tariff"
  inrangeExportTariff: number;
  networkImportTariff: number;
  blendedExportTariff: number;

  // >> Deal and contract attributes
  ppaLength: number;
  exportPPALength: number;
  ppaType: string;
  exportPPAType: string;
  ppaIndex: number;
  exportPPAIndex: number;
  leaseLengthDateEpochSeconds?: number;
  leaseLengthMonths?: number;
  systemLifetimeYears: number;
  vacancyLossRate: number;

  // >> Financial modeling - inputs
  landlordLeaseRevenueShare: number;
  landlordLicenseRevenueShare: number;
  ownerOccupierLicenseRevenueShare: number;
  ownerOccupierLicenseInRangeSaasTariffRate: number;
  energyPriceInflationRate: number;
  costInputsEconomic: { costInflationRates: number[] };
  costInputsInrange?: CostInputsInrange;
  investmentModel: string;
  activeInvestmentModel: "lease" | "license";

  // >> Financial modeling - outputs
  projectCosts: ProjectCosts;
  financialModels: FinancialModelScenarios;
  financialModelsInrange: FinancialModelInRangeScenarios;

  // >> FinancialPerformanceScenarios
  energyFlowMonthlyActuals?: {
    [month: number]: MonthlyEnergyFlowActuals;
  };
  financialModelsMonthlyActuals?: Record<string, FinancialPerformanceScenarios>;

  // >> Marketplace
  minMarketplaceMatchSize: number;
  maxWiredDistanceKm: number;

  // >> HubSpot attributes
  updateHubSpot: boolean;
  hubspotSite?: HubSpotSite;

  // >> Files
  siteFiles: SiteFile[];
  calculations: Calculations;
}

export type RecursivePartial<T> = {
  [K in keyof T]: T[K] extends object
    ? RecursivePartial<T[K]>
    : T[K] | undefined;
};

export type AllowStringForNumbers<T> = {
  [K in keyof T]: T[K] extends number
    ? T[K] | string
    : T[K] extends number | undefined
      ? T[K] | string
      : T[K] extends number[]
        ? T[K] | string[]
        : T[K] extends object
          ? AllowStringForNumbers<T[K]>
          : T[K] extends object | undefined
            ? AllowStringForNumbers<T[K]> | undefined
            : T[K];
};

export type SiteAllowStringValues = AllowStringForNumbers<Site>;

export type PartialSiteAllowStringValues = Partial<AllowStringForNumbers<Site>>;

export type NullableAllowStringForNumbers<T> = {
  [K in keyof T]: T[K] extends number | undefined
    ? T[K] | string | null
    : T[K] extends number
      ? T[K] | string | null
      : T[K] | null;
};

export type ChangeToSiteDefaults = {
  title: string;
  beforeDefaults: PartialSiteAllowStringValues;
  afterDefaults: PartialSiteAllowStringValues;
};

export interface NetworkSite {
  id: string;
  latitude: number;
  longitude: number;
  outcode?: string;
  networkImportTariff: number;
  commerciallyOperationalMonths: number;
  isNetwork?: boolean;
  energyFlowAnnual: AggregateEnergyFlow;
  currencyCode: string;
}

export interface SdmOffer {
  financialModels: FinancialModelScenarios;
  config: SdmMatchConfig;
  dest: {
    id: string;
    dno: string;
    sub: string;
    km: number;
    from_additional_org: boolean;
    is_scenario: boolean;
    is_same_park: boolean;
    minMarketplaceMatchSize: number;
    gridImport: number;
    exported: number;
    availabilityMonths: number;
    latitude?: number;
    longitude?: number;
  };
  emissionsAvoidedYearOne: number;
}

export interface SdmSiteOffer {
  dest: {
    id: string;
    name: string;
    dno: string;
    sub: string;
    km: number;
    orgs: string[];
    from_additional_org: boolean;
    is_scenario: boolean;
    is_same_park: boolean;
    minMarketplaceMatchSize: number;
    gridImport: number;
    exported: number;
    availabilityMonths: number;
  };
  sell: {
    financialModels: FinancialModelScenarios;
    config: SdmMatchConfig;
  };
  buy: {
    financialModels: FinancialModelScenarios;
    config: SdmMatchConfig;
    emissionsAvoidedYearOne: number;
  };
}

export interface EnergyFlowGraphData {
  summerDay: number[];
  winterDay: number[];
  monthly: { [key: string]: number };
}

export interface OfferEnergyFlows {
  match: EnergyFlowGraphData;
  total: EnergyFlowGraphData;
}

export interface SitePreviewError {
  error: string;
  invalid_states: { loc: string[]; msg: string; type: string }[];
}

export interface SiteCalculations {
  // >> Location linked data
  countryCode: string;
  currencyCode: string;
  areaUnit: string;
  dno?: DnoData;
  substationData?: Substation;
  // >> Buildings
  totalBuildingArea: number;
  // >> Demand
  tenantAnnualDemandKwh: number;
  // >> Generation
  pvPanelCount: number;
  generationLossFactors?: Record<string, number>;
  // >> Battery
  batteryLastPriceInputs?: string;
  costInputsBattery: CostInputsBattery;
  // >> Energy flow inputs
  halfHourlyGeneration?: HalfHourlyGeneration;
  // >> Energy flow outputs
  energyFlowAnnual?: AggregateEnergyFlow;
  energyFlowMonthly?: {
    [month: number]: AggregateEnergyFlow;
  };
  monthlyTenantEnergyShare?: number[];
  // >> Tariffs
  blendedExportTariff: number;
  // >> Deal and contract attributes
  leaseLengthMonths: number;
  // >> Financial modeling - inputs
  activeInvestmentModel: "lease" | "license";
  // >> Financial modeling - outputs
  projectCosts?: ProjectCosts;
  financialModels?: FinancialModelScenarios;
  financialModelsInrange?: FinancialModelInRangeScenarios;
  // Extra attributes
  updatedAt?: number;
}

export interface SiteEnergyFlowGraphData {
  match: EnergyFlowGraphData;
  total?: EnergyFlowGraphData;
}

export interface SiteMarketplaceGraphs {
  buy: {
    [key: string]: SiteEnergyFlowGraphData;
  };
  sell: {
    // No "total" for sell matches
    [key: string]: SiteEnergyFlowGraphData;
  };
}

export interface SiteValuesInverter {
  inverterGenerationFactor: number;
}

export interface SiteValuesPvSystem {
  generationLossFactors: Record<string, number>;
  costInputsPv: CostInputsPv;
}

export interface SiteValuesTariffs {
  marketTariff: number;
  tenantTariff: number;
  inrangeExportTariff: number;
}

export interface SiteValuesBuildingProfileData {
  name: string;
  demandCoefficient: number;
  profileShape: string;
  profileDays: string;
  defaultMarketTariff: number;
  maxTenantTariff: number;
  maxNetworkImportTariff: number;
}

export interface PvCurve {
  value: string;
  label: string;
}

export interface SiteValuesOptions {
  pvCurves: Record<string, PvCurve>;
  pvPanels: Record<string, Panel>;
  pvInverterBrands: Record<string, SiteValuesInverter>;
  pvSystems: Record<string, SiteValuesPvSystem>;
  batteries: Record<string, Record<string, BatterySpec>>;
  tariffsDatas: Record<string, SiteValuesTariffs>;
  pvLossFactorLabels: Array<{
    label: string;
    key: string;
    deprecated?: boolean;
  }>;
  buildingProfiles: Record<string, SiteValuesBuildingProfileData>;
}
