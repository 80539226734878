import { SiteAllowStringValues } from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { Tooltip } from "@inrange/theme-components";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { currencyValue } from "./utils";

const Capex: React.FC<{
  site: SiteAllowStringValues;
  currencyCode: string;
}> = ({ site, currencyCode }) => {
  const { siteCalculations } = useContextTS(SiteCalculationsContext);
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Capex</strong>
      </Form.Label>
      <Card.Text className="text-secondary">
        The values marked with an asterisk (*) contribute to the hardware cost.
      </Card.Text>
      <Row className="d-flex flex-wrap">
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>
              Total project cost{" "}
              <Tooltip
                position="left"
                text="Total lifetime costs including all elements"
              />
            </Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.total || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>
              Upfront{" "}
              <Tooltip
                position="left"
                text="Wiring costs + (Installed capacity > 0: Enabling works costs + PV HW + PV install) + (Battery HW + Battery install)"
              />
            </Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.initialInvestment || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        {site.costInputsBattery.batterySpec && (
          <>
            {" "}
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Total hardware{" "}
                  <Tooltip position="left" text="PV + Battery HW" />
                </Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.projectCosts?.totalHardware || 0,
                    currencyCode
                  )}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Total installation{" "}
                  <Tooltip position="left" text="PV + Battery Install" />
                </Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.projectCosts?.totalInstallation || 0,
                    currencyCode
                  )}
                />
              </Form.Group>
            </Col>
          </>
        )}
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>PV hardware</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.pvHardware || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>PV installation</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.pvInstallation || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>
              Total replacement{" "}
              <Tooltip position="left" text="PV + Battery Replacement Capex" />
            </Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                (siteCalculations.projectCosts?.pvReplacementCapex || 0) +
                  (siteCalculations.projectCosts?.batteryReplacementCapex || 0),
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>O&M (Y1)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.maintenanceYearOne || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>O&M (Lifetime)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.maintenance || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>Insurance (Y1)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.insuranceYearOne || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>Insurance (LT)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.insurance || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        {(siteCalculations.projectCosts?.additionalCosts || 0) > 0 && (
          <Col sm={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Additional costs (Lifetime){" "}
                <Tooltip
                  position="left"
                  text="Contingency + Capitalised costs + Capitalised interest + Flat annual cost + Roof rent"
                />
              </Form.Label>
              <Form.Control
                disabled
                value={currencyValue(
                  siteCalculations.projectCosts?.additionalCosts || 0,
                  currencyCode
                )}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default Capex;
