import { getMapboxUrl } from "@inrange/building-manager-api-client";
import {
  Organisation,
  OrgSiteListEntry,
  SimplifiedSdmMatchConfig,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  NetworkSite,
  SdmOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { sortBy } from "@inrange/calculations/utils";
import L, { LatLngBounds, latLngBounds, LatLngExpression } from "leaflet";
import "leaflet.markercluster";
import "leaflet/dist/leaflet.css";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOMServer from "react-dom/server";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import styled from "styled-components";
import {
  loadSessionStoredValue,
  saveSessionStoredValue,
} from "../../utils/sessionStorage";
import { ExistingMatchOnMap } from "../marketplace/marketplace-utils";
import ExistingMatchBuyMarker from "./ExistingMatchBuyMarker";
import ExistingMatchSellMarker from "./ExistingMatchSellMarker";
import "./map.css";
import MapLegend from "./MapLegend";
import {
  MARKETPLACE_COLOR_BLUE,
  MARKETPLACE_COLOR_PURPLE,
  MARKETPLACE_COLOR_YELLOW,
} from "./marketplace-map-styles";
import MySiteBuyExistingBuysMarker from "./MySiteBuyExistingBuysMarker";
import MySiteBuyMarker from "./MySiteBuyMarker";
import MySiteSellExistingSellsMarker from "./MySiteSellExistingSellsMarker";
import MySiteSellMarker from "./MySiteSellMarker";
import NetworkExploreBuyMarker from "./NetworkExploreBuyMarker";
import NetworkExploreExistingBuysMarker from "./NetworkExploreExistingBuysMarker";
import NetworkExploreExistingSellsMarker from "./NetworkExploreExistingSellsMarker";
import NetworkExploreSellMarker from "./NetworkExploreSellMarker";
import OfferBuyMarker from "./OfferBuyMarker";
import OfferSellMarker from "./OfferSellMarker";
import PulseMarker from "./PulseMarker";

const mapUrl = getMapboxUrl("clemysf5c005s01o9u6mw58qc", "tom-inrange");

export const PurpleMarkerIcon = L.divIcon({
  html: ReactDOMServer.renderToString(
    <PulseMarker count={0} type={"purple"} testId={"map-icon-purple"} />
  ),
  className: "custom-marker-purple",
  iconSize: L.point(24, 24, true),
});

export const ProcureSiteIcon = L.divIcon({
  html: ReactDOMServer.renderToString(
    <PulseMarker count={0} type={"procure"} testId={"map-icon-purple"} />
  ),
  className: "custom-marker-procure",
  iconSize: L.point(24, 24, true),
});

export const BlueMarkerIcon = L.divIcon({
  html: ReactDOMServer.renderToString(
    <PulseMarker count={0} type={"blue"} testId={"map-icon-blue"} />
  ),
  className: "custom-marker-blue",
  iconSize: L.point(24, 24, true),
});

export const YellowMarkerIcon = L.divIcon({
  html: ReactDOMServer.renderToString(
    <PulseMarker count={0} type={"yellow"} testId={"map-icon-yellow"} />
  ),
  className: "custom-marker-yellow",
  iconSize: L.point(24, 24, true),
});

const createClusterPurple = function (cluster: any) {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <PulseMarker
        count={cluster.getChildCount()}
        type={"purple"}
        testId={"map-icon-purple"}
      />
    ),
    className: "custom-marker-cluster-purple",
    iconSize: L.point(24, 24, true),
  });
};

const createClusterBlue = function (cluster: any) {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <PulseMarker
        count={cluster.getChildCount()}
        type={"blue"}
        testId={"map-icon-blue"}
      />
    ),
    className: "custom-marker-cluster-blue",
    iconSize: L.point(24, 24, true),
  });
};

const createClusterYellow = function (cluster: any) {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <PulseMarker
        count={cluster.getChildCount()}
        type={"yellow"}
        testId={"map-icon-yellow"}
      />
    ),
    className: "custom-marker-cluster-yellow",
    iconSize: L.point(24, 24, true),
  });
};

interface SetBoundsProps {
  bounds: LatLngBounds;
  boundsOptions: L.FitBoundsOptions;
  allSiteIdsStr: string;
}

const SetBounds: React.FC<SetBoundsProps> = ({
  bounds,
  boundsOptions,
  allSiteIdsStr,
}) => {
  const map = useMap();
  const boundsRef = useRef<LatLngBounds>();
  const boundsOptionsRef = useRef<L.FitBoundsOptions>();
  const allSiteIdsStrRef = useRef<string>();
  const shouldZoomMapRef = useRef<boolean>(false);
  // We zoom the map if the computed bounds change or if the list
  // of sites being shown on the map changes
  shouldZoomMapRef.current =
    boundsRef.current === undefined ||
    !boundsRef.current.equals(bounds) ||
    allSiteIdsStrRef.current !== allSiteIdsStr;
  boundsRef.current = bounds;
  boundsOptionsRef.current = boundsOptions;
  allSiteIdsStrRef.current = allSiteIdsStr;
  useEffect(() => {
    // Running this outside of a useEffect results in:
    // "Cannot update a component (`Map`) while rendering a different component (`SetBounds`)."
    // for some reason which I haven't been able to root cause.
    if (map && shouldZoomMapRef.current) {
      map.fitBounds(boundsRef.current!, boundsOptionsRef.current!);
    }
  });
  return null;
};

const getSiteCenter = (
  site: Site | OrgSiteListEntry | NetworkSite
): LatLngExpression => {
  return [site.latitude, site.longitude];
};

const getSiteCenterWithOffer = (
  site: Site | OrgSiteListEntry | NetworkSite,
  sdmOffer: SdmOffer
): LatLngExpression => {
  return sdmOffer.dest.latitude && sdmOffer.dest.longitude
    ? ([sdmOffer.dest.latitude, sdmOffer.dest.longitude] as LatLngExpression)
    : getSiteCenter(site);
};

const ExploreMapEnergyFlowLines: React.FC<{
  mySites: OrgSiteListEntry[];
  sitesById: Record<string, OrgSiteListEntry>;
  networkSitesById: Record<string, NetworkSite>;
  disableClusteringAtZoom: number;
}> = ({ mySites, sitesById, networkSitesById, disableClusteringAtZoom }) => {
  const map = useMap();
  if (!map || map.getZoom() < disableClusteringAtZoom) {
    return null;
  }

  return (
    <>
      {mySites.flatMap((site) =>
        site.sdmMatches
          .filter((match) => match.isWired)
          .filter(
            // For matches between sites in this org we see the match on both sides, so only show the buyer side
            // If the buer isn't this site, then the seller must be this site, in which case we only
            // want to render if the buyer is not in this org
            (match) => {
              return match.buyerId === site.id || !sitesById[match.buyerId];
            }
          )
          .map((match) => (
            <Polyline
              key={`${match.buyerId}-${match.sellerId}`}
              positions={[
                getSiteCenter(
                  sitesById[match.buyerId] || networkSitesById[match.buyerId]
                ),
                getSiteCenter(
                  sitesById[match.sellerId] || networkSitesById[match.sellerId]
                ),
              ]}
              weight={2}
              opacity={0.5}
              className={`explore-energy-flow-${match.buyerId === site.id ? "buy" : "sell"}`}
            />
          ))
      )}
    </>
  );
};

interface MapProps {
  width: string;
  height: string;
  org: Organisation;
  mySites: OrgSiteListEntry[];
  sitesById: Record<string, OrgSiteListEntry>;
  networkSites: NetworkSite[];
  networkSitesById: Record<string, NetworkSite>;
  onSiteClick: (site: OrgSiteListEntry | Site | NetworkSite) => void;
  offerType: string;
  sdmOffer: SdmOffer | undefined;
  selectedSite: Site | undefined;
  offerSite: OrgSiteListEntry | NetworkSite | undefined;
  existingMatchSites: ExistingMatchOnMap[] | undefined;
}

const Map: React.FC<MapProps> = ({
  width,
  height,
  org,
  mySites,
  sitesById,
  networkSites,
  networkSitesById,
  onSiteClick,
  offerType,
  sdmOffer,
  selectedSite,
  offerSite,
  existingMatchSites,
}) => {
  const [tileLoadingLabel, setTileLoadingLabel] = useState("tile-loading");

  // Track the position/zoom of the explore view, and restore this on re-render
  const [exploreMapState, setExploreMapState] = useState<
    { position: LatLngExpression; zoom: number } | undefined
  >(() => {
    const storedValue = loadSessionStoredValue(org.id, "marketplace-explore");
    return storedValue
      ? (storedValue as { position: LatLngExpression; zoom: number })
      : undefined;
  });
  useEffect(() => {
    if (exploreMapState) {
      saveSessionStoredValue(org.id, "marketplace-explore", exploreMapState);
    }
  }, [org, exploreMapState]);
  const [map, setMap] = useState<L.Map | null>(null);
  const onMoveZoom = useCallback(() => {
    if (map) {
      setExploreMapState({ position: map.getCenter(), zoom: map.getZoom() });
    }
  }, [map]);
  useEffect(() => {
    if (map && selectedSite === undefined) {
      map.on("move", onMoveZoom);
      map.on("zoom", onMoveZoom);
    }
    if (map && selectedSite === undefined && exploreMapState) {
      map.setView(exploreMapState.position, exploreMapState.zoom);
    }
    return () => {
      if (map) {
        map.off("move", onMoveZoom);
        map.off("zoom", onMoveZoom);
      }
    };
  }, [map, onMoveZoom, selectedSite]);

  let allsiteBounds: LatLngBounds | undefined = undefined;
  let offerBounds: LatLngBounds | undefined = undefined;

  const [buysByNetworkSiteId, sellsByNetworkSiteId] = useMemo(() => {
    return [
      mySites.reduce(
        (acc, site) => {
          for (const sdmMatch of site.sdmMatches
            // Matches where this site is the seller
            .filter((match) => match.sellerId === site.id)) {
            if (acc[sdmMatch.buyerId] === undefined) {
              acc[sdmMatch.buyerId] = [];
            }
            acc[sdmMatch.buyerId].push(sdmMatch);
          }
          return acc;
        },
        {} as Record<string, SimplifiedSdmMatchConfig[]>
      ),
      mySites.reduce(
        (acc, site) => {
          for (const sdmMatch of site.sdmMatches
            // Matches where this site is the buyer
            .filter((match) => match.buyerId === site.id)) {
            if (acc[sdmMatch.sellerId] === undefined) {
              acc[sdmMatch.sellerId] = [];
            }
            acc[sdmMatch.sellerId].push(sdmMatch);
          }
          return acc;
        },
        {} as Record<string, SimplifiedSdmMatchConfig[]>
      ),
    ];
  }, [mySites]);

  const mySitesMarkers = mySites
    .sort(sortBy((site) => site.name))
    .map((site: OrgSiteListEntry) => {
      const center = getSiteCenter(site);
      if (allsiteBounds === undefined) {
        allsiteBounds = latLngBounds([center]);
      } else {
        allsiteBounds.extend(center);
      }

      return (
        <Marker
          key={site.id}
          position={center}
          icon={
            site.installedCapacity === 0 ? ProcureSiteIcon : PurpleMarkerIcon
          }
          eventHandlers={{ popupopen: () => onSiteClick(site) }}
        >
          <Popup closeButton={false} autoPan={false}>
            {offerType === "buy" &&
              sellsByNetworkSiteId[site.id] === undefined && (
                <MySiteBuyMarker org={org} site={site} isExplore={true} />
              )}
            {offerType === "buy" &&
              sellsByNetworkSiteId[site.id] !== undefined && (
                <MySiteBuyExistingBuysMarker
                  org={org}
                  site={site}
                  existingSells={sellsByNetworkSiteId[site.id]}
                  orgSitesById={sitesById}
                />
              )}
            {offerType === "sell" &&
              buysByNetworkSiteId[site.id] === undefined && (
                <MySiteSellMarker org={org} site={site} isExplore={true} />
              )}
            {offerType === "sell" &&
              buysByNetworkSiteId[site.id] !== undefined && (
                <MySiteSellExistingSellsMarker
                  org={org}
                  site={site}
                  existingBuys={buysByNetworkSiteId[site.id]}
                  orgSitesById={sitesById}
                />
              )}
          </Popup>
        </Marker>
      );
    });

  const networkSiteMarkers = networkSites
    .sort(sortBy((site) => site.latitude + site.longitude))
    .filter(
      (site) =>
        (offerType === "buy" && sellsByNetworkSiteId[site.id] === undefined) ||
        (offerType === "sell" && buysByNetworkSiteId[site.id] === undefined)
    )
    .map((site: NetworkSite) => {
      const center = getSiteCenter(site);
      return (
        <Marker
          key={site.id}
          position={center}
          icon={BlueMarkerIcon}
          eventHandlers={{ popupopen: () => onSiteClick(site) }}
        >
          <Popup closeButton={false} autoPan={false}>
            {offerType === "buy" ? (
              <NetworkExploreBuyMarker site={site} />
            ) : (
              <NetworkExploreSellMarker site={site} />
            )}
          </Popup>
        </Marker>
      );
    });

  const existingMatchNetworkSiteMarkers = networkSites
    .sort(sortBy((site) => site.latitude + site.longitude))
    .filter(
      (site) =>
        (offerType === "buy" && sellsByNetworkSiteId[site.id] !== undefined) ||
        (offerType === "sell" && buysByNetworkSiteId[site.id] !== undefined)
    )
    .map((site: NetworkSite) => {
      const center = getSiteCenter(site);
      return (
        <Marker
          key={site.id}
          position={center}
          icon={YellowMarkerIcon}
          eventHandlers={{ popupopen: () => onSiteClick(site) }}
        >
          <Popup closeButton={false} autoPan={false}>
            {offerType === "buy" ? (
              <NetworkExploreExistingBuysMarker
                site={site}
                existingSells={sellsByNetworkSiteId[site.id]}
                orgSitesById={sitesById}
              />
            ) : (
              <NetworkExploreExistingSellsMarker
                site={site}
                existingBuys={buysByNetworkSiteId[site.id]}
                orgSitesById={sitesById}
              />
            )}
          </Popup>
        </Marker>
      );
    });

  let selectedSiteMarker: JSX.Element | undefined = undefined;
  if (selectedSite !== undefined) {
    const center = getSiteCenter(selectedSite);
    offerBounds = latLngBounds([center]);
    selectedSiteMarker = (
      <Marker
        key={selectedSite.id}
        position={center}
        icon={offerType === "buy" ? PurpleMarkerIcon : BlueMarkerIcon}
        eventHandlers={{ popupopen: () => onSiteClick(selectedSite) }}
      >
        <Popup closeButton={false} autoPan={false}>
          {offerType === "buy" ? (
            <MySiteBuyMarker org={org} site={selectedSite} isExplore={false} />
          ) : (
            <MySiteSellMarker org={org} site={selectedSite} isExplore={false} />
          )}
        </Popup>
      </Marker>
    );
  }

  let offerSiteMarker: JSX.Element | undefined = undefined;
  if (
    selectedSite != undefined &&
    offerSite !== undefined &&
    sdmOffer !== undefined
  ) {
    const center = getSiteCenterWithOffer(offerSite, sdmOffer);
    if (offerBounds === undefined) {
      offerBounds = latLngBounds([center]);
    } else {
      offerBounds.extend(center);
    }
    offerSiteMarker = (
      <Marker
        key={offerSite.id}
        position={center}
        icon={offerType === "buy" ? BlueMarkerIcon : PurpleMarkerIcon}
        eventHandlers={{ popupopen: () => onSiteClick(offerSite) }}
      >
        <Popup closeButton={false} autoPan={false}>
          {offerType === "sell" ? (
            <OfferSellMarker
              org={org}
              seller={selectedSite}
              site={offerSite}
              sdmOffer={sdmOffer}
            />
          ) : (
            <OfferBuyMarker
              org={org}
              buyer={selectedSite}
              site={offerSite}
              sdmOffer={sdmOffer}
            />
          )}
        </Popup>
      </Marker>
    );
  }

  let existingMatchMarkers: JSX.Element[] = [];
  if (selectedSite !== undefined && existingMatchSites !== undefined) {
    existingMatchMarkers = existingMatchSites
      .sort(sortBy((match) => match.site.latitude + match.site.longitude))
      .filter((match) => match.site !== undefined)
      .map((existingMatch) => {
        const center = getSiteCenter(existingMatch.site);
        if (offerBounds === undefined) {
          offerBounds = latLngBounds([center]);
        } else {
          offerBounds.extend(center);
        }
        return (
          <Marker
            key={existingMatch.site.id}
            position={center}
            icon={YellowMarkerIcon}
            eventHandlers={{ popupopen: () => onSiteClick(existingMatch.site) }}
          >
            <Popup closeButton={false} autoPan={false}>
              {offerType === "buy" ? (
                <ExistingMatchBuyMarker
                  org={org}
                  buyer={selectedSite}
                  site={existingMatch.site}
                  match={existingMatch.match}
                />
              ) : (
                <ExistingMatchSellMarker
                  org={org}
                  seller={selectedSite}
                  site={existingMatch.site}
                  match={existingMatch.match}
                  isSpillMatchUpdated={existingMatch.isSpillMatchUpdated}
                />
              )}
            </Popup>
          </Marker>
        );
      });
  }

  const onClusterClick = (event: any) => {
    const map = event.target;
    const cluster = event.layer;
    const zoom = map._zoom;
    const maxZoom = map._maxZoom;

    if (zoom >= maxZoom) {
      cluster.spiderfy();
    } else {
      cluster.zoomToBounds({ padding: [20, 20] });
    }
  };

  const defaultCenter: LatLngExpression = [
    51.50032365386702, -0.12426640270284971,
  ];
  const mapCenter =
    mySites.length > 0 ? getSiteCenter(mySites[0]) : defaultCenter;
  if (allsiteBounds === undefined) {
    allsiteBounds = latLngBounds([mapCenter]);
  }
  if (offerBounds === undefined) {
    offerBounds = latLngBounds([mapCenter]);
  }

  const renderedSiteIdsStr =
    selectedSite === undefined
      ? `explore-${mySites.map((site) => site.id).join(",")},${networkSites.map((site) => site.id).join(",")}`
      : `selected-${selectedSite?.id},${offerSite?.id},${(existingMatchSites || []).map((existingMatch) => existingMatch.site?.id).join(",")}`;

  // We zoom the map to show all of the org's own sites, but we also want the map zoomed out enough to see country level network sites
  const EXPLORE_MAX_ZOOM = 6;
  const OFFER_MAX_ZOOM = 16;
  const NARROW_CLUSTER_ZOOM = 12;
  const DISABLE_CLUSTER_ZOOM = 14;

  return (
    <MapWrapper
      width={width}
      height={height}
      className={`map-offer-type-${selectedSite !== undefined ? offerType : `map-${offerType}`}`}
      data-testid={tileLoadingLabel}
    >
      <MapContainer
        scrollWheelZoom={false}
        maxZoom={18}
        attributionControl={false}
        ref={setMap}
      >
        <MapLegend
          mySitesLength={mySites.length}
          isShowingSpecificOffer={selectedSite !== undefined}
          offerType={offerType}
          exploreExistingMatchesInNetwork={
            existingMatchNetworkSiteMarkers.length
          }
          existingMatchesLength={existingMatchSites?.length || 0}
        />
        {(!exploreMapState || selectedSite !== undefined) && (
          <SetBounds
            bounds={selectedSite === undefined ? allsiteBounds : offerBounds}
            boundsOptions={
              selectedSite === undefined
                ? {
                    padding: [200, 200],
                    maxZoom: EXPLORE_MAX_ZOOM,
                  }
                : {
                    padding: [200, 200],
                    maxZoom: OFFER_MAX_ZOOM,
                  }
            }
            allSiteIdsStr={renderedSiteIdsStr}
          />
        )}
        <TileLayer
          attribution='&copy; <a href="https://www.mapbox.com/">MapBox</a>'
          url={mapUrl}
          eventHandlers={{
            loading: () => setTileLoadingLabel("tile-loading"),
            load: () => setTileLoadingLabel("tile-loaded"),
          }}
        />

        {selectedSite === undefined && (
          <>
            <MarkerClusterGroup
              showCoverageOnHover={false}
              spiderfyOnMaxZoom={false}
              spiderLegPolylineOptions={{
                opacity: 0.4,
                color: MARKETPLACE_COLOR_PURPLE,
              }}
              zoomToBoundsOnClick={false}
              onClick={onClusterClick}
              iconCreateFunction={createClusterPurple}
              maxClusterRadius={(zoom) => {
                return zoom > NARROW_CLUSTER_ZOOM ? 20 : 80;
              }}
              disableClusteringAtZoom={DISABLE_CLUSTER_ZOOM}
            >
              {mySitesMarkers}
            </MarkerClusterGroup>

            <MarkerClusterGroup
              showCoverageOnHover={false}
              spiderfyOnMaxZoom={false}
              spiderLegPolylineOptions={{
                opacity: 0.4,
                color: MARKETPLACE_COLOR_BLUE,
              }}
              zoomToBoundsOnClick={false}
              onClick={onClusterClick}
              iconCreateFunction={createClusterBlue}
              maxClusterRadius={(zoom) => {
                return zoom > NARROW_CLUSTER_ZOOM ? 20 : 80;
              }}
              disableClusteringAtZoom={DISABLE_CLUSTER_ZOOM}
            >
              {networkSiteMarkers}
            </MarkerClusterGroup>

            <MarkerClusterGroup
              showCoverageOnHover={false}
              spiderfyOnMaxZoom={false}
              spiderLegPolylineOptions={{
                opacity: 0.4,
                color: MARKETPLACE_COLOR_YELLOW,
              }}
              zoomToBoundsOnClick={false}
              onClick={onClusterClick}
              iconCreateFunction={createClusterYellow}
              maxClusterRadius={(zoom) => {
                return zoom > NARROW_CLUSTER_ZOOM ? 20 : 80;
              }}
              disableClusteringAtZoom={DISABLE_CLUSTER_ZOOM}
            >
              {existingMatchNetworkSiteMarkers}
            </MarkerClusterGroup>

            <ExploreMapEnergyFlowLines
              mySites={mySites}
              sitesById={sitesById}
              networkSitesById={networkSitesById}
              disableClusteringAtZoom={DISABLE_CLUSTER_ZOOM}
            />
          </>
        )}

        {selectedSite !== undefined && (
          <>
            {selectedSiteMarker}
            {offerSiteMarker}
            {existingMatchMarkers}
          </>
        )}

        {sdmOffer && selectedSite && offerSite && (
          <Polyline
            positions={[
              getSiteCenter(selectedSite),
              getSiteCenterWithOffer(offerSite, sdmOffer),
            ]}
            weight={2}
            opacity={0.5}
            className="energy-flow"
          />
        )}

        {selectedSite &&
          existingMatchSites &&
          existingMatchSites
            .filter((match) => match.site !== undefined)
            .map((existingMatch) => (
              <Polyline
                key={existingMatch.site.id}
                positions={[
                  getSiteCenter(selectedSite),
                  getSiteCenter(existingMatch.site),
                ]}
                weight={2}
                opacity={0.5}
                className="energy-flow-existing-match"
              />
            ))}
      </MapContainer>
    </MapWrapper>
  );
};

export default Map;

const MapWrapper = styled.div<{
  width: string;
  height: string;
}>`
  position: relative;
  ${({ width, height }) => `
    width: ${width};
    height: ${height};
    z-index: 0;
  `}
`;
