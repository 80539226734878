import {
  getDefaultsPvSystem,
  useSite,
  useSiteFileUpload,
} from "@inrange/building-manager-api-client";
import {
  ChangeToSiteDefaults,
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { ChangeEvent, useState } from "react";
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import {
  isEmptyDiff,
  isSafeToApplyNewDefaults,
  selectChangedDefaults,
} from "../confirm-changes-utils";
import {
  formatNumber,
  formatNumber2dp,
  parseAsNumber,
  roundToDp,
  setMissingValuesZero,
} from "./utils";

const Generation = ({
  site,
  setSite,
  setPendingUpdateSite,
  siteFiles,
  onFileAdded,
  onFileDeleted,
  setSaveDisabled,
  pvDesignDifference,
  pvDesignUploadRef,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  setPendingUpdateSite: (updates: ChangeToSiteDefaults[] | undefined) => void;
  siteFiles?: any[];
  onFileAdded?: () => void;
  onFileDeleted?: (file: any) => void;
  setSaveDisabled?: (disabled: boolean) => void;
  pvDesignDifference?: boolean;
  pvDesignUploadRef?: React.RefObject<HTMLInputElement>;
}) => {
  const { errors, siteCalculations } = useContextTS(SiteCalculationsContext);
  const [displayLossFactors, setDispayLossFactors] = useState(false);
  const { fetchSiteValues } = useSite({
    app: "admin",
    enableNonEssentialQueries: true,
  });
  const { uploadSiteFile } = useSiteFileUpload(site.id);
  const currentPvDesignImage = siteFiles?.find(
    (file) => file.fileType === "pvDesignImage"
  );

  if (site.tenantAnnualDemandKwh === "0") {
    site.tenantAnnualDemandKwh = 0;
  }

  const roofUtilization =
    siteCalculations.halfHourlyGeneration?.roofUtilization;

  const showPvDesignImageUpload =
    onFileAdded && onFileDeleted && setSaveDisabled;
  const onPvDesignImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setSaveDisabled!(true);
      const newPvDesignImage = {
        fileType: "pvDesignImage",
        fileName: file.name,
        description: "PV Design Image",
      };
      uploadSiteFile.mutate(
        {
          file,
          siteFile: newPvDesignImage,
        },
        {
          onSettled: () => setSaveDisabled!(false),
          onSuccess: onFileAdded,
          onError: (error) => {
            console.error("Error uploading PV design image", error);
          },
        }
      );
    }
  };

  const onPvHardwareChange = (
    newPvInverterBrand: string,
    newPvPanelName: string
  ) => {
    const previousPvSystemDefaults = getDefaultsPvSystem(
      fetchSiteValues.data!.options.pvPanels,
      fetchSiteValues.data!.options.pvInverterBrands,
      fetchSiteValues.data!.options.pvSystems,
      siteCalculations.currencyCode,
      site.pvInverterBrand,
      site.pvPanelName
    );
    const pvSystem = getDefaultsPvSystem(
      fetchSiteValues.data!.options.pvPanels,
      fetchSiteValues.data!.options.pvInverterBrands,
      fetchSiteValues.data!.options.pvSystems,
      siteCalculations.currencyCode,
      newPvInverterBrand,
      newPvPanelName
    );

    // Work out how to apply loss factor changes
    const prevDefaultLossFactors = {
      ...previousPvSystemDefaults.generationLossFactors,
    };
    const newDefaultLossFactors = {
      ...pvSystem.generationLossFactors,
    };
    const siteLossFactors = { ...site.generationLossFactors };
    setMissingValuesZero(prevDefaultLossFactors, siteLossFactors);
    setMissingValuesZero(newDefaultLossFactors, siteLossFactors);
    const lossFactorDiffs = selectChangedDefaults(
      { generationLossFactors: prevDefaultLossFactors },
      { generationLossFactors: newDefaultLossFactors }
    );
    const canUpdateLossFactors =
      isEmptyDiff(lossFactorDiffs) ||
      isSafeToApplyNewDefaults(site, lossFactorDiffs);

    // Work out how to apply cost input changes
    const costInputsPvDiff = selectChangedDefaults(
      { costInputsPv: previousPvSystemDefaults.costInputsPv },
      { costInputsPv: pvSystem.costInputsPv }
    );
    const canUpdateCostInputs =
      isEmptyDiff(costInputsPvDiff) ||
      isSafeToApplyNewDefaults(site, costInputsPvDiff);

    // Apply safe changes
    setSite({
      ...(newPvInverterBrand !== site.pvInverterBrand && {
        pvInverterBrand: newPvInverterBrand,
      }),
      ...(newPvPanelName !== site.pvPanelName && {
        pvPanelName: newPvPanelName,
      }),
      ...(canUpdateLossFactors && {
        generationLossFactors: {
          ...site.generationLossFactors,
          ...lossFactorDiffs.afterDefaults.generationLossFactors,
        },
      }),
      ...(canUpdateCostInputs && {
        costInputsPv: {
          ...site.costInputsPv,
          ...costInputsPvDiff.afterDefaults.costInputsPv,
        },
      }),
    });

    // Prompt for any unsafe changes
    if (!canUpdateLossFactors || !canUpdateCostInputs) {
      setPendingUpdateSite([
        ...(canUpdateCostInputs
          ? []
          : [
              {
                title: "Costs and rates",
                ...costInputsPvDiff,
              },
            ]),
        ...(canUpdateLossFactors
          ? []
          : [
              {
                title: "Loss Factors",
                ...lossFactorDiffs,
              },
            ]),
      ]);
    }
  };

  const designSelected = !!site.pvDesignSystemLastUpdatedAt;

  const gicpLossFactor = parseAsNumber(
    siteCalculations.generationLossFactors?.["GICP"] ?? 0
  );
  const totalGenerationLossFactor =
    siteCalculations.halfHourlyGeneration?.totalGenerationLossFactor ?? 0;
  const totalInverseGenerationLossFactorWithoutGicp =
    gicpLossFactor !== 1
      ? roundToDp(
          (1 - totalGenerationLossFactor) * (1 / (1 - gicpLossFactor)),
          6
        )
      : totalGenerationLossFactor;

  const computePr = (generationAfterLossFactors: number | undefined) => {
    if (generationAfterLossFactors === undefined) {
      return 0;
    }
    if (generationAfterLossFactors === 0) {
      return 0;
    }
    const idealGeneration = roundToDp(
      generationAfterLossFactors *
        (1 / totalInverseGenerationLossFactorWithoutGicp),
      6
    );
    return (generationAfterLossFactors / idealGeneration) * 100;
  };

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Generation and PV design</strong>
      </Form.Label>
      {pvDesignDifference && (
        <Card.Text className={`text-danger d-block}`}>
          {designSelected ? "Update the design from" : "Select the design in"}{" "}
          the Automated PV design section to use the selected panel name,
          calculated installed capacity, and loss factor values.
        </Card.Text>
      )}
      <Row className="d-flex flex-wrap">
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="installedCapacity">
            <Form.Label>Installed capacity (kWp)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(site.installedCapacity, 3, 3, false)}
              onChange={(e) =>
                setSite({
                  installedCapacity: formatNumber(e.target.value, 3, 3, false),
                })
              }
              isInvalid={!!errors.installedCapacity}
              data-testid={"installedCapacity"}
            />
            <Form.Control.Feedback type="invalid">
              {errors.installedCapacity}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="pvCurve">
            <Form.Label>Generation curve</Form.Label>
            <Form.Select
              value={site.pvCurve}
              onChange={(e) =>
                setSite({
                  pvCurve: e.target.value,
                })
              }
            >
              {(fetchSiteValues.data!.options.pvCurves
                ? Object.values(fetchSiteValues.data!.options.pvCurves)
                : [
                    { value: "v1", label: "V1" },
                    { value: "v2", label: "V2" },
                  ]
              ).map((pvCurve, index) => (
                <option key={index} value={pvCurve["value"]}>
                  {pvCurve["label"]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="pvForecastProbability">
            <Form.Label>Forecast probability</Form.Label>
            <Form.Select
              value={site.pvForecastProbability}
              onChange={(e) =>
                setSite({
                  pvForecastProbability: e.target.value,
                })
              }
            >
              <option>P50</option>
              <option>P90</option>
              <option>P95</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="pvInverterBrand">
            <Form.Label>Inverters used</Form.Label>
            <Form.Select
              value={site.pvInverterBrand}
              onChange={(e) =>
                onPvHardwareChange(e.target.value, site.pvPanelName)
              }
            >
              {Object.entries(
                fetchSiteValues.data!.options.pvInverterBrands
              ).map(([inverterBrand, _], index) => (
                <option key={index}>{inverterBrand}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="pvPanel">
            <Form.Label>Panel used</Form.Label>
            <Form.Select
              value={site.pvPanelName}
              onChange={(e) =>
                onPvHardwareChange(site.pvInverterBrand, e.target.value)
              }
            >
              {Object.entries(fetchSiteValues.data!.options.pvPanels).map(
                ([panelName, _], index) => (
                  <option key={index}>{panelName}</option>
                )
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="annualGeneration">
            <Form.Label>Annual generation (kWh)</Form.Label>
            <Form.Control
              disabled
              value={formatNumber2dp(
                siteCalculations.energyFlowAnnual?.generation || 0
              )}
              data-testid={"annualGeneration"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="panelCount">
            <Form.Label>Panel count</Form.Label>
            <Form.Control
              disabled
              value={formatNumber(siteCalculations.pvPanelCount, 0, 0) ?? ""}
              data-testid={"panelCount"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="roofUtilisation">
            <Form.Label>Roof utilisation (%)</Form.Label>
            <Form.Control
              disabled
              value={
                parseFloat((roofUtilization || "0") as string).toFixed(4) ?? ""
              }
              data-testid={"roofUtil"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="consumedBhm">
            <Form.Label>Consumed on-site (kWh)</Form.Label>
            <Form.Control
              disabled
              value={formatNumber2dp(
                siteCalculations.energyFlowAnnual?.behindMeter || 0
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="exported">
            <Form.Label>Exported (matched) (kWh)</Form.Label>
            <Form.Control
              disabled
              value={formatNumber2dp(
                siteCalculations.energyFlowAnnual?.networkExport || 0
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="exported">
            <Form.Label>Exported (spill) (kWh)</Form.Label>
            <Form.Control
              disabled
              value={formatNumber2dp(
                siteCalculations.energyFlowAnnual?.exported || 0
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="curtailed">
            <Form.Label>Curtailed (kWh)</Form.Label>
            <Form.Control
              disabled
              value={formatNumber2dp(
                siteCalculations.energyFlowAnnual?.curtailed || 0
              )}
              data-testid={"curtailed"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="pr">
            <Form.Label>Performance ratio (%)</Form.Label>
            <Form.Control
              disabled
              value={formatNumber2dp(
                computePr(siteCalculations.energyFlowAnnual?.generation)
              )}
            />
          </Form.Group>
        </Col>
        {showPvDesignImageUpload && (
          <Col md="auto">
            <Form.Group
              className="mb-3"
              style={{ display: "flex", flexDirection: "column" }}
              controlId="pvDesignImage"
            >
              <Form.Label>Designed PV image</Form.Label>
              <Form.Control
                type="file"
                style={{ display: "none" }}
                accept="image/*, application/pdf"
                ref={pvDesignUploadRef}
                onChange={onPvDesignImageUpload}
              />
              <Stack direction="horizontal" gap={3}>
                <label
                  htmlFor="pvDesignImage"
                  className="btn btn-success"
                  data-testid={"pvDesignImageUploadButton"}
                >
                  Choose file
                </label>
                <div data-testid={"pvDesignImageUploadFileName"}>
                  {!uploadSiteFile.isLoading &&
                    (currentPvDesignImage?.fileName || "No file chosen")}{" "}
                  {currentPvDesignImage &&
                    !currentPvDesignImage.fileVersionId &&
                    " (unsaved)"}
                </div>
                <div>
                  {uploadSiteFile.isLoading && <>(Uploading...)</>}
                  {currentPvDesignImage?.fileName &&
                    !uploadSiteFile.isLoading && (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => onFileDeleted(currentPvDesignImage)}
                      >
                        Delete file
                      </Button>
                    )}
                </div>
              </Stack>
            </Form.Group>
          </Col>
        )}
        <Col>
          {!displayLossFactors && (
            <div
              style={{
                textAlign: "center",
                color: "rgb(13, 110, 253)",
                marginTop: "20px",
              }}
            >
              <a
                onClick={() => setDispayLossFactors(true)}
                style={{
                  cursor: "pointer",
                }}
              >
                Show loss factors
              </a>
            </div>
          )}
        </Col>
      </Row>
      {displayLossFactors && (
        <Row className="d-flex flex-wrap">
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="ghi">
              <Form.Label>GHI (kWh/m²)</Form.Label>
              <Form.Control
                type="text"
                value={siteCalculations.halfHourlyGeneration?.locationGhi ?? ""}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="ghi">
              <Form.Label>GHI override (kWh/m²)</Form.Label>
              <Form.Control
                type="text"
                value={site.overrideGHI ?? ""}
                onChange={(e) =>
                  setSite({
                    overrideGHI: e.target.value,
                  })
                }
                isInvalid={!!errors.overrideGHI}
              />
              <Form.Control.Feedback type="invalid">
                {errors.overrideGHI}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="pvYoyForecastVariability">
              <Form.Label>YoY variability</Form.Label>
              <Form.Control
                type="text"
                value={site.pvYoyForecastVariability}
                onChange={(e) =>
                  setSite({
                    pvYoyForecastVariability: e.target.value,
                  })
                }
                isInvalid={!!errors.pvYoyForecastVariability}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pvYoyForecastVariability}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {fetchSiteValues
            .data!.options.pvLossFactorLabels.filter(
              (lossFactor: any, _index: number) => !lossFactor.deprecated
            )
            .map((lossFactor: any, index: number) => (
              <Col key={index} sm={4}>
                <Form.Group className="mb-3" controlId={lossFactor.key}>
                  <Form.Label>{lossFactor.label}</Form.Label>
                  <Form.Control
                    type="text"
                    value={site.generationLossFactors[lossFactor.key] ?? 0}
                    onChange={(e) =>
                      setSite({
                        generationLossFactors: {
                          ...site.generationLossFactors,
                          [lossFactor.key]: e.target.value,
                        },
                      })
                    }
                    isInvalid={
                      !!errors[`generationLossFactors.${lossFactor.key}`]
                    }
                    data-testid={lossFactor.key}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[`generationLossFactors.${lossFactor.key}`]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ))}
        </Row>
      )}
    </Card>
  );
};

export default Generation;
