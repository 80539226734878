import { BatterySpec } from "@inrange/building-manager-api-client/models-site";

export const formatBatterySpecs = (spec: BatterySpec) => {
  if (spec.capacityMwh <= 1) {
    return `${(spec.powerMw * 1000).toFixed(1)} kW / ${(spec.capacityMwh * 1000).toFixed(1)} kWh`;
  }
  return `${spec.powerMw} MW / ${spec.capacityMwh} MWh`;
};

export const formatBatteryModel = (spec: BatterySpec) => {
  return spec.count! > 1
    ? `${spec.modelName} x ${spec.count}`
    : `${spec.modelName}`;
};
