export const ACTIVITY_LOG_MAP = {
  siteOwnerships: "Site Ownerships",
  siteMPANs: "Site MPANs",
  buildings: "Buildings",
  totalBuildingArea: "Total Building Area",
  updatedBy: "Updated By",
  updatedByName: "Updated By Name",
  name: "Name",
  address: "Address",
  notes: "Notes",
  commerciallyOperationalDateEpochSeconds: "Commerially Operational Date",
  leaseLengthDateEpochSeconds: "Lease Length (Date)",
  postcode: "Postcode",
  latitude: "Latitude",
  longitude: "Longitude",
  annualGenerationLoss: "Annual Generation Loss",
  roofUtilization: "Roof Utilization",
  panelCostPerSqm: "Panel Cost Per SqM",
  gridConnectionCost: "Grid Connection Cost",
  marketTariff: "Market Tariff",
  tenantTariff: "Tenant Tariff",
  gridExportTariff: "Grid Export Tariff",
  inrangeExportTariff: "Spill export tariff",
  landlordLeaseRevenueShare: "Landlord Lease Revenue Share",
  landlordLicenseRevenueShare: "Landlord License Revenue Share",
  ownerOccupierLicenseRevenueShare: "Owner Occupier License Revenue Share",
  pvDesignSystemLastUpdatedAt: "PV Design System Last Updated At",
  pvDesignSystemLastUpdatedBy: "PV Design System Last Updated By",
  ownerOccupierLicenseInRangeSaasTariffRate:
    "Owner Occupier InRange SaaS Tariff Rate",
  demandCoefficientKWhPerM2: "Demand Coefficient (kWh/m²)",
  buildingProfile: "Building Profile",
  tenantEnergyShare: "Tenant Energy Share",
  investmentModel: "Investment Model",
  ghi: "GHI",
  hubspotUpdateTime: "Hubspot Update Time",
  hubspotRecordID: "HubSpot Record ID",
  installedCapacity: "Installed Capacity",
  tenantAnnualDemandKwh: "Demand (kWh)",
  demand: "Demand (kWh)",
  behindMeter: "Behind Meter (kWh)",
  curtailed: "Curtailed (kWh)",
  exported: "Exported (kWh)",
  generation: "Generation (kWh)",
  exportLimit: "Export Limit (kVA)",
  gridImport: "Grid Import (kWh)",
  networkImport: "Network Import (kWh)",
  networkExport: "Network Export (kWh)",
  batteryChargeLoss: "Battery Charge Loss (kWh)",
  energyFlowSummerDay: "Energy Flow Summer Day",
  energyFlowWinterDay: "Energy Flow Winter Day",
  hardware: "Hardware Cost",
  pvHardware: "PV Hardware Cost",
  batteryHardware: "Battery Hardware Cost",
  totalHardware: "Total Hardware Cost",
  installation: "Installation Cost",
  pvInstallation: "PV Installation Cost",
  batteryInstallation: "Battery Installation Cost",
  totalInstallation: "Total Installation Cost",
  total: "Total Cost",
  initialInvestment: "Total Upfront Capex",
  panel: "Panel Cost",
  mounting: "Mounting Cost",
  inverter: "Inverter Cost",
  electrical: "Electrical Cost",
  bos: "BOS Cost",
  misc: "Misc. Cost",
  insurance: "Insurance Cost (Lifetime)",
  insuranceYearOne: "Insurance Cost (Year 1)",
  maintenance: "Maintenance Cost (Lifetime)",
  maintenanceYearOne: "Maintenance Cost (Year 1)",
  licenseRevenue: "License Revenue",
  onSiteAvoidance: "On Site Avoidance",
  networkAvoidance: "Network Avoidance",
  totalAvoidance: "Emissions avoided year 1",
  generationAvoidance: "Generation Avoidance",
  networkImportTariff: "Network Import Tariff",
  blendedExportTariff: "Blended Export Tariff",
  ownerOccupierIRR: "Owner Occupier IRR",
  landlordIRR: "Landlord IRR",
  ownerOccupierPaybackMonths: "Owner Occupier Payback (Months)",
  landlordPaybackMonths: "Landlord Payback (Months)",
  "financialModels.landlord.license.revenue": "License: Landlord Revenue (Y1)",
  "financialModels.landlord.license.lifetimeRevenue":
    "License: Landlord Revenue (Lifetime)",
  "financialModels.landlord.license.inrangeNetRevenue":
    "License: Landlord InRange Revenue (Y1)",
  "financialModels.landlord.license.irr": "License: Landlord IRR",
  "financialModels.landlord.license.paybackMonths":
    "License: Landlord Payback (Months)",
  "financialModels.landlord.lease.revenue": "Lease: Landlord Revenue (Y1)",
  "financialModels.landlord.lease.lifetimeRevenue":
    "Lease: Landlord Revenue (Lifetime)",
  "financialModels.ownerOccupier.license.revenue":
    "License: Owner Occupier Revenue (Y1)",
  "financialModels.ownerOccupier.license.lifetimeRevenue":
    "License: Owner Occupier Revenue (Lifetime)",
  "financialModels.ownerOccupier.license.savings":
    "License: Owner Occupier Savings (Y1)",
  "financialModels.ownerOccupier.license.lifetimeSavings":
    "License: Owner Occupier Savings (Lifetime)",
  "financialModels.ownerOccupier.license.inrangeNetRevenue":
    "License: Owner Occupier InRange Revenue (Y1)",
  "financialModels.ownerOccupier.license.irr": "License: Owner Occupier IRR",
  "financialModels.ownerOccupier.license.paybackMonths":
    "License: Owner Occupier Payback (Months)",
  "financialModels.ownerOccupier.lease.revenue":
    "Lease: Owner Occupier Revenue (Y1)",
  "financialModels.ownerOccupier.lease.lifetimeRevenue":
    "Lease: Owner Occupier Revenue (Lifetime)",
  "financialModels.ownerOccupier.lease.savings":
    "Lease: Owner Occupier Savings (Y1)",
  "financialModels.ownerOccupier.lease.lifetimeSavings":
    "Lease: Owner Occupier Savings (Lifetime)",
  "financialModels.tenant.savings": "Tenant Savings (Y1)",
  "financialModels.tenant.lifetimeSavings": "Tenant Savings (Lifetime)",
  "financialModelsInrange.lease.netRevenue": "Lease: InRange Net Revenue (Y1)",
  "financialModelsInrange.lease.irr": "Lease: InRange Equity IRR",
  "financialModelsInrange.lease.minADSCR": "Lease: InRange Min ADSCR",
  "financialModelsInrange.lease.paybackMonths":
    "Lease: InRange Debt Payback (Months)",
  "financialModelsInrange.grossEnergyRevenue": "Gross Energy Revenue (Y1)",
  "costInputsInrange.epcCostPerKWp": "InRange: EPC Cost Per kWp",
  "costInputsInrange.constructionMonths": "Construction Months",
  "costInputsInrange.equityFraction": "Lease: InRange Equity Fraction",
  "costInputsInrange.interestRateConstruction":
    "Lease: InRange Interest Rate (Construction)",
  "costInputsInrange.interestRateOperation":
    "Lease: InRange Interest Rate (Operation)",
  "costInputsInrange.vatRate": "Lease: InRange VAT Rate",
  "costInputsInrange.corporateTaxRate": "Lease: InRange Corporate Tax Rate",
  "costInputsInrange.targetMinimumAnnualDSCR":
    "Lease: InRange Target Minimum Annual DSCR",
  "costInputsInrange.targetMinimumDSCR": "Lease: InRange Target Minimum DSCR",
  initialCostPerKWp: "PV cost per kWp",
  totalCostPerKwp: "Total cost per kWp",
  panelCostPerKWp: "Panel Cost Per kWp",
  mountingCostPerKWp: "Mounting Cost Per kWp",
  inverterCostPerKWp: "Inverter Cost Per kWp",
  electricalCostPerKWp: "Electrical Cost Per kWp",
  bosCostPerKWp: "BOS Cost Per kWp",
  miscCostPerKWp: "Misc. Cost Per kWp",
  enablingWorksCosts: "Enabling Works Cost",
  wiringCosts: "Wiring Costs",
  hardwareCostPerKWp: "PV hw. cost per kWp",
  installationCostPerKWp: "PV installation Cost Per kWp",
  insuranceCostPerKWp: "Insurance Cost Per kWp",
  maintenanceCostPerKWp: "Maintenance Cost Per kWp",
  replacementCapex: "PV Replacement Capex",
  pvReplacementCapex: "PV Replacement Capex",
  replacementCapexRate: "PV Replacement Capex Rate",
  replacementCapexYear: "PV Replacement Capex Year",
  energyPriceInflationRate: "Energy Price Inflation Rate",
  costInflationRates: "Cost Inflation Rates",
  pvPanelName: "PV Panel Model",
  pvInverterBrand: "PV Inverter Brand",
  batteryPower: "Battery Power (MW)",
  "costInputsBattery.batterySpec.brand": "Battery Brand",
  "costInputsBattery.batterySpec.powerMw": "Battery Power (MW)",
  batteryCapacity: "Battery Capacity (MWh)",
  "costInputsBattery.batterySpec.capacityMwh": "Battery Capacity (MWh)",
  "costInputsBattery.batterySpec": "Battery Specification",
  "costInputsBattery.batterySpec.depthOfDischarge":
    "Battery Depth of Discharge",
  batteryModel: "Battery Model",
  batteryCost: "Battery Cost",
  "costInputsBattery.batterySpec.hardwareCost": "Battery Hardware Cost",
  "costInputsBattery.installationCost": "Battery Installation Cost",
  "costInputsBattery.batterySpec.count": "Battery Count",
  "costInputsBattery.batterySpec.modelName": "Battery Model",
  "costInputsBattery.batterySpec.modelNotes": "Battery Model Notes",
  "costInputsBattery.batterySpec.roundTripEfficiency":
    "Battery Round Trip Efficiency",
  "costInputsBattery.batterySpec.warranty.capacity":
    "Battery End of Warranty Capacity",
  "costInputsBattery.batterySpec.warranty.periodCycles":
    "Battery Warranty Cycles",
  "costInputsBattery.batterySpec.warranty.periodYears":
    "Battery Warranty Years",
  batteryReplacementYear: "Battery Replacement Capex Year",
  batteryReplacementCapex: "Battery Replacement Capex",
  sdmMatches: "SDM Matches",
  "sdmMatches.volume": "Volume",
  "sdmMatches.buyerId": "Site ID - Buyer",
  "sdmMatches.sellerId": "Site ID - Seller",
  "sdmMatches.tariff": "Tariff",
  ppaContractType: "PPA Contract type",
  ppaType: "PPA Type",
  ppaIndex: "PPA Index",
  ppaLength: "PPA Length",
  exportPPAType: "Export PPA type",
  exportPPAIndex: "Export PPA index",
  exportPPALength: "Export PPA length",
  orgID: "Org ID",
  "siteOwnerships.ownership": "Org Site Ownership",
  park: "Org Park",
  "siteOwnerships.name": "Org Name",
  "buildings.geometry": "Building Geometry",
  "buildings.id": "Building ID",
  "buildings.polygonType": "Building Polygon Type",
  "buildings.sourcePolygonType": "Building Source Polygon Type",
  "buildings.surfaceAreaSqM": "Building Surface Area (m²)",
  operationalStatus: "Operational Status",
  "siteFiles.date": "Date",
  "siteFiles.description": "Description",
  "siteFiles.fileName": "File Name",
  "siteFiles.fileType": "File Type",
  "siteFiles.invoiceAmount": "Invoice Amount",
  "siteFiles.invoiceDueDate": "Invoice Due Date",
  "siteFiles.invoiceNumber": "Invoice Number",
  "siteFiles.isLandlord": "Is Landlord",
  "siteFiles.issueDate": "Issue Date",
  "siteFiles.statementAmount": "Statement Amount",
  "siteFiles.statementNumber": "Statement Number",
  isWired: "Wired?",
  systemLifetimeYears: "System Lifetime (Years)",
  flatAnnualCost: "Flat Annual Cost",
  roofRentPerM2: "Roof Rent Per m²",
  roofRentBtm: "Roof Rent BTM",
  roofRentExport: "Roof Rent Export",
  contingency: "Contingency (%)",
  capitalisedCost: "Capitalized cost (%)",
  capitalisedInterest: "Capitalized interest (%)",
  vacancyLossRate: "Vacancy Loss Rate (%)",
  profileShape: "Profile Shape",
  profileDays: "Profile Days",
  pvCurve: "PV Curve",
  overrideGHI: "Override GHI",
  score: "Site score",
  siteFiles: "Site Files",
};

export const formatFieldName = (
  lossFactorsByKey: Record<string, any>,
  fieldName: string
) => {
  if (fieldName.startsWith("generationLossFactors.")) {
    const key = fieldName.replace("generationLossFactors.", "");
    return lossFactorsByKey[key]?.label || fieldName;
  } else if (ACTIVITY_LOG_MAP[fieldName]) {
    return ACTIVITY_LOG_MAP[fieldName];
  } else if (fieldName.indexOf(".") !== -1) {
    // For older fields we only have the friendly name mapped to the last part
    // of the changed field name path because we didn't use to include the full path.
    // This code handles falling back to the last part of the path.
    const lastFieldNamePart = fieldName.split(".").slice(-1)[0];
    if (ACTIVITY_LOG_MAP[lastFieldNamePart]) {
      return ACTIVITY_LOG_MAP[lastFieldNamePart];
    }
  }
  // If a friendly name has not been provided, render the raw field path
  return fieldName;
};
