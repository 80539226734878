import axiosConfig from "./axiosConfig";

const getSiteCustomerCashflow = async (
  authToken,
  siteId,
  siteName,
  cashflowType
) => {
  const response = await axiosConfig.get(
    `/site/${siteId}/cashflow?cashFlowType=${cashflowType}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      responseType: "blob",
    }
  );
  // Create a URL for the blob
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;

  // Set the download attribute with a default file name
  const currentDateTime = new Date()
    .toISOString()
    .replace(/T/, "-")
    .replace(/:/g, "-")
    .replace(/\..+/, "");

  // Sanitize site name - lowercase, replace all space with dash, remove special characters, replace repeated dashes with a single dash
  const sanitizedSiteName = siteName
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "")
    .replace(/-+/g, "-");

  link.setAttribute(
    "download",
    `${siteId}-${sanitizedSiteName}-cashflow-${cashflowType}-${currentDateTime}.xlsx`
  );

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default getSiteCustomerCashflow;
