import axiosConfig from "./axiosConfig";

const getSites = async (authToken, siteIdsChunkStr, userOrgId) => {
  const { data } = await axiosConfig.get(
    `/sites?siteIds=${siteIdsChunkStr}${userOrgId ? `&userOrgId=${userOrgId}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getSites;
