import area from "@turf/area";
import { polygon } from "@turf/helpers";
import intersect from "@turf/intersect";
import hash from "object-hash";

export const closeCoordinates = (coordinates) => {
  const closedCoordinates = [...coordinates];
  if (coordinates[0][0] !== coordinates[coordinates.length - 1][0]) {
    // If the polygon is not closed close it
    closedCoordinates.push(coordinates[0]);
  }
  return closedCoordinates;
};

export const isIntersectingWithPolygon = (
  building,
  polygons,
  threshold = 0.5
) => {
  // Convert the input polygon to a Turf.js object
  const coordinates = building.geometry.coordinates[0];
  const closedCoordinates = closeCoordinates(coordinates);
  const buildingPolygon = polygon([closedCoordinates]);

  // Iterate over the array of polygons
  for (const polygon of polygons) {
    // Calculate the intersection between the input polygon and the current array polygon
    const intersection = intersect(buildingPolygon, polygon);

    // Check if there is an intersection and if its area is greater than the threshold
    if (
      intersection &&
      area(intersection) / area(buildingPolygon) > threshold
    ) {
      return true; // Intersection area is greater than the threshold, return true
    }
  }

  // No intersection found with area greater than the threshold
  return false;
};

export const bmaBuildingToOsmBuilding = (building) => {
  // Transform the building from our shape
  // to match the shape of buildings from OSM
  const surface_area_sqm = building.surfaceAreaSqM;
  const center_distance = 0; // For backward compatibility
  const coordinates =
    typeof building.geometry.coordinates === "string"
      ? JSON.parse(building.geometry.coordinates)
      : building.geometry.coordinates;
  const sourceGeometryCoordinates = building.sourceGeometry
    ? typeof building.sourceGeometry.coordinates === "string"
      ? JSON.parse(building.sourceGeometry.coordinates)
      : building.sourceGeometry.coordinates
    : null;
  const geometry = {
    type: building.geometry.type,
    coordinates,
  };
  const sourceGeometry = {
    type: building.sourceGeometry?.type,
    coordinates: sourceGeometryCoordinates,
  };
  const polygonType = building.polygonType;
  const sourcePolygonType = building.sourcePolygonType;
  const coordinatesHash = hash(JSON.stringify(geometry.coordinates));
  const key = building.id || coordinatesHash;
  return {
    center_distance,
    geometry,
    sourceGeometry,
    polygonType,
    sourcePolygonType,
    surface_area_sqm,
    key,
    coordinatesHash,
  };
};
