const getCustomerAppDomain = () => {
  const adminDomain = document.location.hostname;
  // TODO: Postgres: Get rid of this
  if (adminDomain === "d1kvc6ntvvceyi.cloudfront.net") {
    return "d1mhijjof864hl.cloudfront.net";
  }
  return adminDomain === "localhost"
    ? "http://localhost:3001"
    : "https://" +
        (adminDomain.endsWith(".inrange.dev")
          ? adminDomain.replace(/^admin-/, "customer-")
          : "app.inrange.io");
};

export default getCustomerAppDomain;
