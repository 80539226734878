import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AxiosError } from "axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import _deleteSite from "../http/deleteSite";
import deleteSiteMarketplaceMatch from "../http/deleteSiteMarketplaceMatch";
import getSite from "../http/getSite";
import getSiteCustomerCashflow from "../http/getSiteCustomerCashflow";
import getSiteEnergyActuals from "../http/getSiteEnergyActuals";
import getSiteEnergyFlow from "../http/getSiteEnergyFlow";
import getSiteInrangeSpvCashflow from "../http/getSiteInrangeSpvCashflow";
import getSiteLog from "../http/getSiteLog";
import getSiteSdmEnergyFlow from "../http/getSiteSdmEnergyFlow";
import getSiteValues from "../http/getSiteValues";
import postPreviewSite from "../http/postPreviewSite";
import postSite from "../http/postSite";
import postSiteCopy from "../http/postSiteCopy";
import postSiteMarketplaceMatch from "../http/postSiteMarketplaceMatch";
import putSite from "../http/putSite";
import { Site, SitePreviewError, SiteValuesOptions } from "../models/site";

export interface UseSiteProps {
  siteId?: string;
  userOrgId?: string;
  enableNonEssentialQueries?: boolean;
  app: "admin" | "customer";
}

const useSite = ({
  siteId,
  userOrgId,
  enableNonEssentialQueries,
  app,
}: UseSiteProps) => {
  if (enableNonEssentialQueries === undefined) {
    // This enables useSite to be mounted multiple times without
    // triggering automatic fetches of the site log and the site default values
    enableNonEssentialQueries = true;
  }
  const isAdmin = app === "admin";
  const userOrgIdArg = userOrgId;

  const queryClient = useQueryClient();
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchSiteValues: UseQueryResult<
    {
      site: Site;
      options: SiteValuesOptions;
    },
    unknown
  > = useQuery(
    ["site-values"],
    async () => {
      return getSiteValues(await getToken());
    },
    {
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
      enabled: enableNonEssentialQueries && isAdmin,
    }
  );

  const LOSS_FACTORS_BY_KEY: Record<string, any> = {};
  ((fetchSiteValues.data || {})?.options?.pvLossFactorLabels || []).forEach(
    (lf: any, index: number) => {
      if (LOSS_FACTORS_BY_KEY[lf.key]) {
        throw new Error(`Duplicate loss factor key: ${lf.key}`);
      }
      LOSS_FACTORS_BY_KEY[lf.key] = { ...lf, index };
    }
  );

  const fetchSite: UseQueryResult<{ site: Site }, unknown> = useQuery(
    ["site", siteId],
    async () => {
      return getSite(await getToken(), siteId, userOrgId);
    },
    {
      enabled: !!siteId,
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
    }
  );

  const fetchSiteLog = useQuery(
    ["site", siteId, "log"],
    async () => {
      return getSiteLog(await getToken(), siteId);
    },
    {
      enabled: !!siteId && isAdmin && enableNonEssentialQueries,
      placeholderData: { siteLog: [] },
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
    }
  );

  const fetchSiteEnergyFlow = useMutation(
    async ({
      forecastYear,
      groupByDay,
      actuals,
    }: {
      forecastYear?: string;
      groupByDay: boolean;
      actuals: boolean;
    }) =>
      getSiteEnergyFlow(
        await getToken(),
        siteId,
        forecastYear,
        groupByDay,
        actuals
      )
  );

  const fetchSiteEnergyActuals = useMutation(
    async ({
      actualsYear,
      groupByDay,
    }: {
      actualsYear?: string;
      groupByDay: boolean;
    }) =>
      getSiteEnergyActuals(await getToken(), siteId, actualsYear, groupByDay)
  );

  const fetchSiteSdmEnergyFlow = useMutation(
    async ({
      buyerSiteId,
      sellerSiteId,
      groupByDay,
    }: {
      buyerSiteId: string;
      sellerSiteId: string;
      groupByDay: boolean;
    }) =>
      getSiteSdmEnergyFlow(
        await getToken(),
        buyerSiteId,
        sellerSiteId,
        groupByDay
      )
  );

  const fetchSiteCustomerCashflow = useMutation(
    async ({
      siteName,
      cashflowType,
    }: {
      siteName: string;
      cashflowType: string;
    }) =>
      getSiteCustomerCashflow(await getToken(), siteId, siteName, cashflowType)
  );

  const fetchSiteInrangeSpvCashflow = useMutation(
    async ({ siteName }: { siteName: string }) =>
      getSiteInrangeSpvCashflow(await getToken(), siteId, siteName)
  );

  const updateSite = useMutation(
    async (update: any) => putSite(await getToken(), siteId, userOrgId, update),
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(["site", siteId]);
        if (!isAdmin) {
          queryClient.invalidateQueries(["org", userOrgIdArg]);
        }
      },
    }
  );

  const createSite = useMutation(
    async (site: any) => postSite(await getToken(), site, userOrgId),
    {
      onSuccess: () => {
        if (!isAdmin) {
          queryClient.invalidateQueries(["org", userOrgIdArg]);
        }
      },
    }
  );

  const copySite = useMutation(
    async ({ siteId, copyBody }: { siteId: string; copyBody: any }) =>
      postSiteCopy(await getToken(), siteId, copyBody)
  );

  const previewSite = useMutation<
    { site: Site },
    AxiosError<SitePreviewError>,
    unknown
  >(async (payload: any) => postPreviewSite(await getToken(), payload), {
    // Cold starts can make the first couple of runs take longer than the API gateway 30 second
    // timeout so we allow two retries
    retry: (failureCount: number, error: AxiosError) => {
      // Disable retry if the error status is 400
      if (error.response?.status === 400) {
        return false;
      }
      // Allow retry for other errors, up to 2 times
      return failureCount < 2;
    },
  });

  const deleteSite = useMutation(
    async (siteId: string) => _deleteSite(await getToken(), siteId),
    {
      onSuccess: () => {
        // Only done from Admin, so no need to invalidate the Customer App only org query which returns sites
      },
    }
  );

  const siteAddMatch = useMutation(
    async (match: any) => {
      return await postSiteMarketplaceMatch(await getToken(), siteId, match);
    },
    {
      onSettled: (_data, _error, match) => {
        queryClient.invalidateQueries(["site", match.buyerId]);
        queryClient.invalidateQueries(["site", match.sellerId]);
        // Only done from Admin, so no need to invalidate the Customer App only org query which returns sites
      },
    }
  );

  const siteDeleteMatch = useMutation(
    async (match: any) => {
      return await deleteSiteMarketplaceMatch(await getToken(), siteId, match);
    },
    {
      onSettled: (_data, _error, match) => {
        queryClient.invalidateQueries(["site", match.buyerId]);
        queryClient.invalidateQueries(["site", match.sellerId]);
        // Only done from Admin, so no need to invalidate the Customer App only org query which returns sites
      },
    }
  );

  return {
    fetchSiteValues,
    LOSS_FACTORS_BY_KEY,
    fetchSite,
    fetchSiteLog,
    fetchSiteEnergyFlow,
    fetchSiteSdmEnergyFlow,
    fetchSiteCustomerCashflow,
    fetchSiteInrangeSpvCashflow,
    fetchSiteEnergyActuals,
    createSite,
    previewSite,
    updateSite,
    deleteSite,
    copySite,
    siteAddMatch,
    siteDeleteMatch,
  };
};

export default useSite;
