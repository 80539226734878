import {
  AllowStringForNumbers,
  CostInputsBattery,
  PartialSiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import { sortBy } from "@inrange/calculations/utils.ts";
import { Tooltip } from "@inrange/theme-components";
import { Icons } from "@inrange/theme-components/icons";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import _ from "lodash";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import BatteryTooltip from "./BatteryTooltip";
import { formatNumber, stripBatteryQuotes } from "./utils";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const BatteryBrandIcons = {
  GivEnergy: Icons.givenergy,
  SolarEdge: Icons.solaredge,
  Dyness: Icons.dyness,
  Sungrow: Icons.sungrow,
  Huawei: Icons.huawei,
};

const BatteryResults = ({
  costInputsBattery,
  setSite,
  baseline,
  capacityDebugData,
  results,
  currencyCode,
  isOwnerOccupied,
}: {
  costInputsBattery: AllowStringForNumbers<CostInputsBattery>;
  setSite: (site: PartialSiteAllowStringValues) => void;
  baseline: any;
  capacityDebugData: any;
  results: any[];
  currencyCode: string;
  isOwnerOccupied: boolean;
}) => {
  const [showCapacityDebug, setShowCapacityDebug] = useState(false);

  const batterySpecForComparison = (spec: any) => {
    const result = { ...spec };
    delete result.modelNotes;
    for (const key of [
      "powerMw",
      "capacityMwh",
      "depthOfDischarge",
      "roundTripEfficiency",
    ]) {
      if (result[key] !== undefined) {
        result[key] = Number(result[key]);
      }
    }
    return stripBatteryQuotes(result);
  };

  const columns = [
    {
      header: "Brand",
      id: "brand",
      accessorFn: (row: any) => row.costInputsBattery.batterySpec,
      sortingFn: "sortFnBrand",
      sortDescFirst: false,
      cell: (info: any) => {
        const row = info.row.original;
        return (
          <>
            {BatteryBrandIcons[row.costInputsBattery.batterySpec.brand] && (
              <Tooltip
                text={`${row.costInputsBattery.batterySpec.modelName} x ${row.costInputsBattery.batterySpec.count}`}
                position="right"
              >
                <img
                  src={
                    BatteryBrandIcons[row.costInputsBattery.batterySpec.brand]
                  }
                  alt={row.brand}
                  style={{ width: "40px" }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      header: "Power (MW)",
      id: "power_mw",
      accessorFn: (row: any) =>
        row.costInputsBattery.batterySpec.powerMw *
        row.costInputsBattery.batterySpec.count,
      sortingFn: "sortFnPower",
      sortDescFirst: false,
      cell: (info: any) => formatNumber(info.getValue(), 0, 6, false),
    },
    {
      header: "Capacity (MWh)",
      id: "capacity_mwh",
      accessorFn: (row: any) =>
        row.costInputsBattery.batterySpec.capacityMwh *
        row.costInputsBattery.batterySpec.count,
      sortingFn: "sortFnCapacity",
      sortDescFirst: false,
      cell: (info: any) => {
        const row = info.row.original;
        const usableChargeKwh =
          row.costInputsBattery.batterySpec.capacityMwh *
          row.costInputsBattery.batterySpec.count *
          row.costInputsBattery.batterySpec.depthOfDischarge *
          1000.0;
        const annualCyclesPercent =
          (row.energyFlowAnnual.batteryDischarge / usableChargeKwh / 366) * 100;
        return (
          <>
            {formatNumber(info.getValue(), 0, 6, false)}{" "}
            <span style={{ display: "inline-block" }}>
              (
              <BatteryTooltip
                batterySpec={row.costInputsBattery.batterySpec}
                energyFlowAnnual={row.energyFlowAnnual}
                energyFlowMonthly={row.energyFlowMonthly}
              >
                <span
                  style={{
                    textDecoration: "dotted underline 1px",
                  }}
                >
                  {formatNumber(annualCyclesPercent, 0, 0, false)}%
                </span>
              </BatteryTooltip>
              )
            </span>
          </>
        );
      },
    },
    {
      header: `IRR (${isOwnerOccupied ? "OO" : "LL"}, %)`,
      id: "irr",
      accessorFn: (row: any) =>
        row.financialModels[isOwnerOccupied ? "ownerOccupier" : "landlord"]
          .license.irr,
      sortingFn: "sortIrr",
      sortDescFirst: true,
      cell: (info: any) => {
        const row = info.row.original;
        const ownershipKey = isOwnerOccupied ? "ownerOccupier" : "landlord";
        const updated = row.financialModels[ownershipKey].license.irr;
        const initial = baseline.financialModels[ownershipKey].license.irr || 0;
        const delta = updated - initial;
        return (
          <>
            {formatNumber(updated * 100, 0, 2, false)}% <br />(
            {formatNumber(delta * 100, 0, 2, false)})
          </>
        );
      },
    },
    {
      header: "Battery cost",
      id: "battery_cost",
      accessorFn: (row: any) =>
        formatCurrency(
          row.costInputsBattery.batterySpec.hardwareCost +
            row.costInputsBattery.installationCost,
          currencyCode
        ),
      sortingFn: "sortFnBatteryCost",
      sortDescFirst: false,
      cell: (info: any) => info.getValue(),
    },
    {
      header: "Total fulfilled by on-site (%)",
      id: "tenant_demand_share",
      accessorFn: (row: any) => row,
      sortingFn: "sortFnTenantDemand",
      sortDescFirst: true,
      cell: (info: any) => {
        const row = info.row.original;
        const updated =
          row.energyFlowAnnual.behindMeter / row.energyFlowAnnual.demand;
        const initial =
          baseline.energyFlowAnnual.behindMeter /
          baseline.energyFlowAnnual.demand;
        const delta = updated - initial;
        return (
          <>
            {formatNumber(updated * 100, 0, 2, false)}% <br />(
            {formatNumber(delta * 100, 0, 2, false)})
          </>
        );
      },
    },
    {
      header: "Tenant energy share (%)",
      id: "tenant_energy_share",
      accessorFn: (row: any) => row,
      sortingFn: "sortFnTenantGeneration",
      sortDescFirst: true,
      cell: (info: any) => {
        const row = info.row.original;
        const updated =
          row.energyFlowAnnual.behindMeter / row.energyFlowAnnual.generation;
        const initial =
          baseline.energyFlowAnnual.behindMeter /
          baseline.energyFlowAnnual.generation;
        const delta = updated - initial;
        return (
          <>
            {formatNumber(updated * 100, 0, 2, false)}% <br />(
            {formatNumber(delta * 100, 0, 2, false)})
          </>
        );
      },
    },
    {
      header: "Export (kWh/yr)",
      id: "export_kwh",
      accessorFn: (row: any) => row,
      sortingFn: "sortFnExport",
      sortDescFirst: true,
      cell: (info: any) => {
        const row = info.row.original;
        const updated = row.energyFlowAnnual.exported;
        const initial = baseline.energyFlowAnnual.exported;
        const delta = updated - initial;
        return (
          <>
            {formatNumber(updated, 0, 0, true)} <br />(
            {formatNumber(delta, 0, 0, true)})
          </>
        );
      },
    },
    {
      header: "Curtailed (kWh/yr)",
      id: "curtailment_kwh",
      accessorFn: (row: any) => row,
      sortingFn: "sortFnCurtailed",
      sortDescFirst: true,
      cell: (info: any) => {
        const row = info.row.original;
        const updated = row.energyFlowAnnual.curtailed;
        const initial = baseline.energyFlowAnnual.curtailed;
        const delta = updated - initial;
        return (
          <>
            {formatNumber(updated, 0, 0, true)} <br />(
            {formatNumber(delta, 0, 0, true)})
          </>
        );
      },
    },
    {
      header: "",
      id: "actions",
      accessorFn: (row: any) => row,
      cell: ({ row }: { row: any }) => {
        const siteBatteryCostInputs = {
          ...costInputsBattery,
          batterySpec: batterySpecForComparison(costInputsBattery.batterySpec),
        };
        const rowBatteryCostInputs = {
          ...row.original.costInputsBattery,
          batterySpec: batterySpecForComparison(
            row.original.costInputsBattery.batterySpec
          ),
        };
        const disabled = _.isEqual(siteBatteryCostInputs, rowBatteryCostInputs);
        const batterySpecToApply = stripBatteryQuotes(
          row.original.costInputsBattery.batterySpec
        );
        return (
          <Button
            variant="success"
            onClick={() => {
              setSite({
                batteryEnergyFlowPayloadHash:
                  row.original.batteryEnergyFlowPayloadHash,
                batteryEnergyFlowFileVersionId:
                  row.original.batteryEnergyFlowFileVersionId,
                batteryLastPriceInputs: row.original.batteryLastPriceInputs,
                costInputsBattery: {
                  ...costInputsBattery,
                  ...row.original.costInputsBattery,
                  batterySpec: batterySpecToApply,
                },
              });
            }}
            disabled={disabled}
            style={{ padding: "0.375rem", width: "100%", fontSize: "90%" }}
            size="sm"
          >
            {disabled ? "Selected" : "Select"}
          </Button>
        );
      },
      disableSortBy: true,
    },
  ];

  return (
    <>
      <ResultsTable
        columns={columns}
        data={results}
        isOwnerOccupied={isOwnerOccupied}
      />
      {!showCapacityDebug && (
        <a
          onClick={() => setShowCapacityDebug(true)}
          className="text-primary"
          style={{
            cursor: "pointer",
            textDecoration: "none",
          }}
        >
          Show capacity range information
        </a>
      )}
      {showCapacityDebug && (
        <>
          <p>
            Auto-detected capacity range (MWh):{" "}
            {capacityDebugData.range.join(", ")}
          </p>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Month</th>
                <th>Avg day exportable generation (MWh)</th>
                <th>Avg day grid import (MWh)</th>
                <th>Max usable capacity (MWh)</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 12 }).map((_, monthIndex) => (
                <tr key={monthIndex}>
                  <td>{months[monthIndex]}</td>
                  <td>
                    {capacityDebugData.monthlyInputs.excessGenerationMwh[
                      monthIndex
                    ].toFixed(3)}
                  </td>
                  <td>
                    {capacityDebugData.monthlyInputs.gridImportMwh[
                      monthIndex
                    ].toFixed(3)}
                  </td>
                  <td>
                    {capacityDebugData.monthlyInputs.maxUsableCapacityMwh[
                      monthIndex
                    ].toFixed(3)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

const ResultsTable = ({
  columns,
  data,
  isOwnerOccupied,
}: {
  columns: any[];
  data: any[];
  isOwnerOccupied: boolean;
}) => {
  const [sorting, setSorting] = useState([
    { id: columns[3].id ?? "", desc: true },
  ]);
  const table = useReactTable({
    data,
    columns,
    sortingFns: {
      sortFnBrand: sortBy(
        (row: any) => row.original.costInputsBattery.batterySpec.brand
      ),
      sortFnPower: sortBy(
        (row: any) => row.original.costInputsBattery.batterySpec.powerMw
      ),
      sortFnCapacity: sortBy(
        (row: any) =>
          row.original.costInputsBattery.batterySpec.capacityMwh *
          row.original.costInputsBattery.batterySpec.count
      ),
      sortIrr: sortBy(
        (row: any) =>
          row.original.financialModels[
            isOwnerOccupied ? "ownerOccupier" : "landlord"
          ].license.irr
      ),
      sortFnBatteryCost: sortBy(
        (row: any) => row.original.costInputsBattery.batterySpec.hardwareCost
      ),
      sortFnTenantGeneration: sortBy(
        (row: any) =>
          row.original.energyFlowAnnual.behindMeter /
          row.original.energyFlowAnnual.generation
      ),
      sortFnTenantDemand: sortBy(
        (row: any) =>
          row.original.energyFlowAnnual.behindMeter /
          row.original.energyFlowAnnual.demand
      ),
      sortFnExport: sortBy(
        (row: any) => row.original.energyFlowAnnual.exported
      ),
      sortFnCurtailed: sortBy(
        (row: any) => row.original.energyFlowAnnual.curtailed
      ),
    },
    state: {
      sorting,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table
      striped
      bordered
      hover
      responsive
      style={{
        fontSize: "80%",
        height: "600px",
        overflow: "scroll",
        display: "block",
        border: "1px solid #dee2e6",
      }}
      id="battery-results-table"
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{ cursor: "pointer", padding: "0.3rem" }}
                onClick={header.column.getToggleSortingHandler()}
              >
                <div style={{ display: "flex" }}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  <SortArrow isSorted={header.column.getIsSorted()} />
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={{
                  padding: "0.3rem",
                  backgroundColor: cell.row.original.costInputsBattery
                    .batterySpec.modelName
                    ? "#e6f3ff"
                    : "transparent",
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const SortArrow = ({ isSorted }: { isSorted: false | "asc" | "desc" }) => {
  if (!isSorted) {
    return (
      <span
        style={{
          width: 8,
          display: "inline-block",
        }}
      >
        &nbsp;
      </span>
    );
  }
  const arrow = isSorted === "desc" ? Icons.sortDesc : Icons.sortAsc;

  return <img alt="view" src={arrow} />;
};

const formatCurrency = (value: number, currencyCode: string) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits: 0,
  }).format(value);
};

export default BatteryResults;
