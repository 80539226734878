import {
  useOrganisation,
  useSiteUpdate,
} from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import { useState } from "react";
import { Alert, Button, Form, InputGroup, Table } from "react-bootstrap";

const OrgParks = ({
  organisation,
  sites,
  parksAlertStyle,
  setParksAlertStyle,
  parksAlertMessage,
  setParksAlertMessage,
}: {
  organisation: Organisation;
  sites: Site[];
  parksAlertStyle: string;
  setParksAlertStyle: (style: string) => void;
  parksAlertMessage: string | undefined;
  setParksAlertMessage: (message: string | undefined) => void;
}) => {
  const { updateOrganisation, invalidateOrg } = useOrganisation(
    organisation.id
  );
  const siteUpdate = useSiteUpdate();
  const [newPark, setNewPark] = useState<string>("");
  const [parksIsRemoving, setParksIsRemoving] = useState<{
    [key: string]: boolean;
  }>({});

  const onAddPark = (park: string) => {
    updateOrganisation.mutate(
      {
        organisation: {
          parks: Array.from(new Set([...organisation.parks, park.trim()])),
        },
      },
      {
        onError: (error: any) => {
          alert(error?.response?.data?.message || error);
        },
        onSuccess: () => {
          setParksIsRemoving({ ...parksIsRemoving, [park]: false });
        },
      }
    );
  };

  const onRemovePark = async (park: string) => {
    setParksIsRemoving({ ...parksIsRemoving, [park]: true });
    updateOrganisation.mutate(
      {
        organisation: {
          parks: organisation.parks.filter((p) => p !== park),
        },
      },
      {
        onError: (error: any) => {
          setParksIsRemoving({ ...parksIsRemoving, [park]: false });
          alert(error?.response?.data?.message || error);
        },
        onSuccess: () => {
          setParksIsRemoving({ ...parksIsRemoving, [park]: false });
        },
      }
    );

    const sitesToUpdate = sites.filter((site) =>
      site.siteOwnerships.some((so) => so.park === park)
    );

    if (sitesToUpdate.length === 0) {
      setParksAlertMessage(undefined);
      return;
    }

    setParksAlertStyle("light");
    setParksAlertMessage(`Updating ${sitesToUpdate.length} sites...`);

    const mutations = sitesToUpdate.map((site) =>
      siteUpdate.mutateAsync({
        siteId: site.id,
        payload: {
          newOwnerships: site.siteOwnerships
            .filter((so) => so.park === park)
            .map((so) => {
              so.park = undefined;
              return so;
            }),
        },
      })
    );

    try {
      const results = await Promise.allSettled(mutations);
      const successfulUpdates = results.filter(
        (result) => result.status === "fulfilled"
      ).length;
      const failedUpdates = results.filter(
        (result) => result.status === "rejected"
      ).length;

      if (failedUpdates === 0) {
        setParksAlertStyle("success");
        setParksAlertMessage(`Successfully updated ${successfulUpdates} sites`);
      } else {
        setParksAlertStyle("danger");
        setParksAlertMessage(
          `Failed to update ${failedUpdates} out of ${sitesToUpdate.length} sites`
        );
      }
    } catch (error) {
      setParksAlertStyle("danger");
      setParksAlertMessage(`An unexpected error occurred while updating sites`);
    }
    invalidateOrg();
  };

  const addPark = (park: string) => {
    onAddPark(park);
    setNewPark("");
  };

  return (
    <>
      {parksAlertMessage && (
        <Alert
          variant={parksAlertStyle}
          onClose={() => setParksAlertMessage(undefined)}
          dismissible={parksAlertStyle !== "light"}
        >
          <div>{parksAlertMessage}</div>
        </Alert>
      )}
      <div>
        <h4>Manage parks ({organisation.parks.length})</h4>
        {organisation.parks.length > 0 && (
          <>
            <Table bordered style={{ fontSize: 14 }}>
              <thead>
                <tr>
                  <th>Park name</th>
                  <th>Number of sites</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {organisation?.parks?.sort().map((park) => (
                  <tr key={park}>
                    <td>{park}</td>
                    <td>
                      {
                        sites.filter((site) =>
                          site.siteOwnerships.some((so) => so.park === park)
                        ).length
                      }
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => onRemovePark(park)}
                        disabled={parksIsRemoving[park]}
                      >
                        Remove park
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}

        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Park name"
            type="text"
            value={newPark}
            onChange={(e) => setNewPark(e.target.value)}
          />
          <Button
            variant="primary"
            onClick={() => addPark(newPark)}
            style={{ marginRight: 10 }}
            disabled={newPark.trim().length === 0}
          >
            Add park
          </Button>
        </InputGroup>
      </div>
    </>
  );
};

export default OrgParks;
