import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  useMutation,
  UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import deleteOrganisationUser from "../http/deleteOrganisationUser";
import getOrganisation from "../http/getOrganisation";
import getSites from "../http/getSites";
import postOrganisation from "../http/postOrganisation";
import postOrganisationUser from "../http/postOrganisationUser";
import putOrganisation from "../http/putOrganisation";
import { Organisation, OrgSiteListEntry } from "../models/organisation";
import { Site, SiteOwnership } from "../models/site";

const useOrganisation = (orgId?: string, userOrgId?: string) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const queryClient = useQueryClient();
  const fetchOrganisation: UseQueryResult<
    { organisation: Organisation; sites: OrgSiteListEntry[] },
    unknown
  > = useQuery(
    ["org", orgId],
    async () => {
      return getOrganisation(await getToken(), orgId, userOrgId);
    },
    { enabled: !!orgId, staleTime: 5 * 60 * 1000 }
  );

  const chunk = <T>(arr: T[], chunkSize: number): T[][] => {
    const chunks: T[][] = [];
    const tmp = [...arr];
    while (tmp.length) {
      chunks.push(tmp.splice(0, chunkSize));
    }
    return chunks;
  };

  const useOrganisationSites = (siteOwnerships: SiteOwnership[] = []) => {
    return useQueries<UseQueryOptions<{ sites: Site[] }>[]>(
      chunk(
        siteOwnerships
          .filter((so) => so.siteID !== undefined)
          .map((so) => so.siteID!),
        50
      )
        .map((siteIdsChunk) => siteIdsChunk.join(","))
        .map((siteIdsChunkStr) => {
          return {
            queryKey: ["sites", siteIdsChunkStr],
            queryFn: async () =>
              getSites(await getToken(), siteIdsChunkStr, userOrgId),
            staleTime: 5 * 60 * 1000,
            onSuccess: (data) => {
              data.sites.forEach((site) => {
                queryClient.setQueryData(["site", site.id], { site });
              });
            },
          };
        })
    );
  };

  const updateOrganisation: UseMutationResult<void, unknown, unknown, unknown> =
    useMutation(
      async (update: unknown) =>
        putOrganisation(await getToken(), orgId, update),
      {
        onSuccess: (_) => {
          queryClient.invalidateQueries(["org", orgId]);
        },
      }
    );

  const addOrgUser: UseMutationResult<void, unknown, unknown, unknown> =
    useMutation(
      async (data: unknown) => {
        return postOrganisationUser(await getToken(), orgId, data, userOrgId);
      },
      {
        onSuccess: (_) => {
          queryClient.invalidateQueries(["org", orgId]);
        },
      }
    );

  const removeOrgUser: UseMutationResult<void, unknown, object, unknown> =
    useMutation(
      async (user: object) => {
        return deleteOrganisationUser(await getToken(), orgId, user);
      },
      {
        onSuccess: (_) => {
          queryClient.invalidateQueries(["org", orgId]);
        },
      }
    );

  const invalidateOrg = () => {
    queryClient.invalidateQueries(["org", orgId]);
  };

  const createOrg: UseMutationResult<
    void,
    unknown,
    { organisation: Organisation },
    unknown
  > = useMutation(async (payload: { organisation: Organisation }) =>
    postOrganisation(await getToken(), payload)
  );

  return {
    addOrgUser,
    removeOrgUser,
    createOrg,
    fetchOrganisation,
    useOrganisationSites,
    updateOrganisation,
    invalidateOrg,
  };
};

export default useOrganisation;
