import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQueries } from "react-query";
import postSiteBatteryAnalysis from "../http/postSiteBatteryAnalysis";

const useSiteBatteryAnalysis = ({
  onlySyntheticBatteries,
  payload,
}: {
  onlySyntheticBatteries: boolean;
  payload: any;
}) => {
  const { getToken } = useKindeAuth();

  const batteryQueries = useQueries(
    Array.from({ length: 12 }, (_, i) => i + 1).map((page) => {
      return {
        // Including payload in the key ensures that we cache the results with a payload
        // under a different key than the results without a payload.
        queryKey: ["site", "battery", page, payload !== undefined],
        queryFn: async () =>
          postSiteBatteryAnalysis(
            await getToken(),
            page,
            onlySyntheticBatteries,
            payload
          ),
        staleTime: Infinity,
        enabled: false,
      };
    })
  );

  return {
    batteryQueries,
  };
};

export default useSiteBatteryAnalysis;
