import {
  Panel,
  Site,
  SiteValuesInverter,
  SiteValuesOptions,
  SiteValuesPvSystem,
  SiteValuesTariffs,
} from "./site";

const isEmptyObjectOrArray = (value: unknown): boolean => {
  if (value !== null && typeof value === "object") {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return Object.keys(value).length === 0;
    }
  }
  return false;
};

const defaultSiteIgnoreKeys = new Set([
  "createdBy",
  "createdAt",
  "updatedAt",
  "id",
  "halfHourlyDemand",
]);

export const getDefaultsPvSystem = (
  pvPanels: Record<string, Panel>,
  pvInverterBrands: Record<string, SiteValuesInverter>,
  pvSystems: Record<string, SiteValuesPvSystem>,
  currencyCode: string,
  pvInverterBrand: string,
  pvPanelName: string
) => {
  const pvSystem =
    pvSystems[`${currencyCode}#${pvInverterBrand}#${pvPanelName}`] ||
    pvSystems[`${currencyCode}#${pvInverterBrand}`];
  return {
    generationLossFactors: {
      ...pvSystem.generationLossFactors,
      LID: parseFloat(
        (1 - pvPanels[pvPanelName].warranty.yearOneEfficiency).toFixed(6)
      ),
      IBEX: parseFloat(
        (
          1 - pvInverterBrands[pvInverterBrand].inverterGenerationFactor
        ).toFixed(6)
      ),
    },
    costInputsPv: pvSystem.costInputsPv,
  };
};

export const getDefaultsTariffs = (
  tariffsDatas: Record<string, SiteValuesTariffs>,
  currencyCode: string
) => {
  return tariffsDatas[currencyCode];
};

export const defaultSite = (
  defaultSite: Record<string, any>,
  siteOptions: SiteValuesOptions,
  siteId: string,
  latitude?: number,
  longitude?: number,
  buildingKey?: string,
  address?: string
) => {
  let name = "";
  if (address) {
    name = address.split(", ").slice(0, 3).join(", ");
  }

  const defaultSiteClean = Object.fromEntries(
    Object.entries(defaultSite).filter(
      ([key, value]) =>
        value !== null &&
        value !== "" &&
        !defaultSiteIgnoreKeys.has(key) &&
        !isEmptyObjectOrArray(value)
    )
  );

  const site: Partial<Site & { siteNewURLBuildingID: string }> = {
    ...defaultSiteClean,
    name: name,
    address: address || "",
    buildings: [],
    id: siteId,
    generationLossFactors: getDefaultsPvSystem(
      siteOptions.pvPanels,
      siteOptions.pvInverterBrands,
      siteOptions.pvSystems,
      "GBP",
      defaultSiteClean.pvInverterBrand,
      defaultSiteClean.pvPanelName
    ).generationLossFactors,
  };

  if (latitude) {
    site.latitude = latitude;
  }
  if (longitude) {
    site.longitude = longitude;
  }
  if (buildingKey) {
    site.siteNewURLBuildingID = buildingKey;
  }
  return site;
};

// In test mode we create all sites on Jan 1st 2023 so that their inferred start date
// is jan 1st 2024, which is a leap year
export const CREATED_AT_JAN_1_2023 = 1672531200;
