import axiosConfig from "./axiosConfig";

const getSiteEnergyActuals = async (
  authToken,
  siteId,
  actualsYear,
  groupByDay
) => {
  const queryArgs = [];
  queryArgs.push(`year=${actualsYear}`);
  if (groupByDay) {
    queryArgs.push("groupByDay=true");
  }
  const response = await axiosConfig.get(
    `/site/${siteId}/site_energy_actuals${queryArgs.length > 0 ? `?${queryArgs.join("&")}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      responseType: "blob",
    }
  );
  // Create a URL for the blob
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;

  // Set the download attribute with a default file name
  link.setAttribute(
    "download",
    `${siteId}-energy-actuals${groupByDay ? "-daily" : ""}-year-${actualsYear}.csv`
  );

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default getSiteEnergyActuals;
