import {
  getDefaultsPvSystem,
  getDefaultsTariffs,
  useSite,
} from "@inrange/building-manager-api-client";
import {
  NullableAllowStringForNumbers,
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import { useEffect, useRef } from "react";

export const useSetDefaultsOnCurrencyChange = (
  site: SiteAllowStringValues,
  setSite: (
    site: NullableAllowStringForNumbers<PartialSiteAllowStringValues>
  ) => void,
  currencyCode: string
) => {
  const { fetchSiteValues } = useSite({
    app: "admin",
    enableNonEssentialQueries: true,
  });
  const siteRef = useRef(site);
  siteRef.current = site;
  useEffect(() => {
    if (!fetchSiteValues.data) {
      // Site values not loaded yet
      return;
    }
    if (siteRef.current.currencyCode === currencyCode) {
      // Not change from initial site state
      return;
    }

    // Currency code has changed - reset to default values for the new country
    const defaultsPvSystem = getDefaultsPvSystem(
      fetchSiteValues.data!.options.pvPanels,
      fetchSiteValues.data!.options.pvInverterBrands,
      fetchSiteValues.data!.options.pvSystems,
      currencyCode,
      siteRef.current.pvInverterBrand,
      siteRef.current.pvPanelName
    );
    const defaultsTariffs = getDefaultsTariffs(
      fetchSiteValues.data.options.tariffsDatas,
      currencyCode
    );
    setSite({
      currencyCode: currencyCode,
      costInputsPv: defaultsPvSystem.costInputsPv,
      marketTariff: defaultsTariffs.marketTariff,
      tenantTariff: defaultsTariffs.tenantTariff,
      inrangeExportTariff: defaultsTariffs.inrangeExportTariff,
      batteryEnergyFlowPayloadHash: null,
      batteryEnergyFlowFileVersionId: null,
      costInputsBattery: {
        ...siteRef.current.costInputsBattery,
        // Remove battery to avoid having to do a currency conversion here
        batterySpec: undefined,
        installationCost: 0,
      },
    });
  }, [setSite, fetchSiteValues.data, currencyCode]);
};
