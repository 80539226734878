import {
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { Tooltip } from "@inrange/theme-components";
import { currencySymbol } from "@inrange/theme-components/formatting";
import { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { formatNumber, formatNumber2dp } from "./utils";

const Cost = ({
  site,
  setSite,
  isBulkEdit,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  isBulkEdit?: boolean;
}) => {
  const { errors, siteCalculations } = useContextTS(SiteCalculationsContext);
  const [displayCostInflationRates, setDisplayCostInflationRates] =
    useState(false);

  const range = (start: number, end: number): number[] => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const costInflationRatesView = isBulkEdit ? undefined : (
    <>
      {range(2, parseFloat(site.systemLifetimeYears as string) || 30).map(
        (year) => {
          return (
            <Col sm={3} key={year}>
              <Form.Group
                className="mb-3"
                controlId={`costInflationRates${year - 1}`}
              >
                <Form.Label>Year {year} inflation (%)</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    site.costInputsEconomic.costInflationRates[year - 1] || 0.02
                  }
                  onChange={(e) => {
                    const newCostInflationRates =
                      site.costInputsEconomic.costInflationRates;
                    const arraySize =
                      parseFloat(site.systemLifetimeYears as string) || 25;
                    newCostInflationRates.length = arraySize;
                    for (let i = 0; i < arraySize; i++) {
                      if (newCostInflationRates[i] === undefined) {
                        newCostInflationRates[i] = 0.02;
                      }
                    }
                    newCostInflationRates[year - 1] = e.target.value;
                    setSite({
                      costInputsEconomic: {
                        ...site.costInputsEconomic,
                        costInflationRates: newCostInflationRates,
                      },
                    });
                  }}
                  isInvalid={
                    !!errors[
                      `costInputsEconomic.costInflationRates.${year - 1}`
                    ]
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`costInputsEconomic.costInflationRates.${year - 1}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        }
      )}
    </>
  );

  const showCostInflationRatesLink = (
    <Col style={{ paddingTop: "40px" }}>
      <a
        onClick={() => setDisplayCostInflationRates(true)}
        style={{
          cursor: "pointer",
          color: "rgb(13, 110, 253)",
        }}
      >
        Show cost inflation rates
      </a>
    </Col>
  );

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Costs and rates</strong>
      </Form.Label>
      <Row className="d-flex flex-wrap">
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.hardwareCostPerKWp"
          >
            <Form.Label>PV hw. cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber2dp(
                site.costInputsPv.hardwareCostPerKWp,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    hardwareCostPerKWp: formatNumber2dp(e.target.value, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.hardwareCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.hardwareCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.installationCostPerKWp"
          >
            <Form.Label>PV install cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber2dp(
                site.costInputsPv.installationCostPerKWp,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    installationCostPerKWp: formatNumber2dp(
                      e.target.value,
                      false
                    ),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.installationCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.installationCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {!isBulkEdit && (
          <>
            <Col sm={3}>
              <Form.Group className="mb-3" controlId="initialCostPerKWp">
                <Form.Label>
                  PV cost per kWp{" "}
                  <Tooltip position="left" text="PV Hardware + Install" />
                </Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={formatNumber2dp(
                    siteCalculations.projectCosts?.initialCostPerKWp || 0
                  )}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group className="mb-3" controlId="totalCostPerKwp">
                <Form.Label>
                  Total cost per kWp{" "}
                  <Tooltip
                    position="left"
                    text="Total upfront project cost / installed capacity"
                  />
                </Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={formatNumber2dp(
                    siteCalculations.projectCosts?.totalCostPerKwp || 0
                  )}
                />
              </Form.Group>
            </Col>
          </>
        )}
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsInrange.epcCostPerKWp"
          >
            <Form.Label>EPC cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={
                site.costInputsInrange!.epcCostPerKWp
                  ? formatNumber2dp(
                      site.costInputsInrange!.epcCostPerKWp,
                      false
                    )
                  : ""
              }
              onChange={(e) =>
                setSite({
                  costInputsInrange: {
                    ...site.costInputsInrange!,
                    epcCostPerKWp: formatNumber2dp(e.target.value, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsInrange.epcCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsInrange.epcCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.enablingWorksCosts"
          >
            <Form.Label>
              Enabling works cost
              {!isBulkEdit && (
                <> ({currencySymbol(siteCalculations.currencyCode)})</>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              value={formatNumber2dp(
                site.costInputsPv.enablingWorksCosts,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    enablingWorksCosts: formatNumber2dp(e.target.value, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.enablingWorksCosts"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.enablingWorksCosts"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {(isBulkEdit ||
          (site.sdmMatches && site.sdmMatches.some((match) => match.isWired)) ||
          parseFloat(site.costInputsPv.wiringCosts as string) > 0) && (
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="costInputsPv.wiringCosts">
              <Form.Label>
                Wiring cost
                {!isBulkEdit && (
                  <> ({currencySymbol(siteCalculations.currencyCode)})</>
                )}
              </Form.Label>
              <Form.Control
                type="text"
                value={formatNumber2dp(site.costInputsPv.wiringCosts, false)}
                onChange={(e) =>
                  setSite({
                    costInputsPv: {
                      ...site.costInputsPv,
                      wiringCosts: formatNumber2dp(e.target.value, false),
                    },
                  })
                }
                isInvalid={!!errors["costInputsPv.wiringCosts"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsPv.wiringCosts"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.maintenanceCostPerKWp"
          >
            <Form.Label>O&M cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber2dp(
                site.costInputsPv.maintenanceCostPerKWp,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    maintenanceCostPerKWp: formatNumber2dp(
                      e.target.value,
                      false
                    ),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.maintenanceCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.maintenanceCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.insuranceCostPerKWp"
          >
            <Form.Label>Insurance cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber2dp(
                site.costInputsPv.insuranceCostPerKWp,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    insuranceCostPerKWp: formatNumber2dp(e.target.value, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.insuranceCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.insuranceCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.flatAnnualCost">
            <Form.Label>
              Flat annual cost
              {!isBulkEdit && (
                <> ({currencySymbol(siteCalculations.currencyCode)})</>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsPv.flatAnnualCost,
                2,
                6,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    flatAnnualCost: formatNumber(e.target.value, 2, 6, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.flatAnnualCost"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.flatAnnualCost"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.roofRentPerM2">
            <Form.Label>
              Rent per m<sup>2</sup>
              {!isBulkEdit && (
                <> ({currencySymbol(siteCalculations.currencyCode)})</>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(site.costInputsPv.roofRentPerM2, 2, 6, false)}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    roofRentPerM2: formatNumber(e.target.value, 2, 6, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.roofRentPerM2"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.roofRentPerM2"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.roofRentBtm">
            <Form.Label>Roof rent BTM (% of rev)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(site.costInputsPv.roofRentBtm, 2, 6, false)}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    roofRentBtm: formatNumber(e.target.value, 2, 6, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.roofRentBtm"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.roofRentBtm"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.roofRentExport">
            <Form.Label>Roof rent exp (% of rev)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsPv.roofRentExport,
                2,
                6,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    roofRentExport: formatNumber(e.target.value, 2, 6, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.roofRentExport"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.roofRentExport"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.contingency">
            <Form.Label>Contingency (%)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(site.costInputsPv.contingency, 2, 6, false)}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    contingency: formatNumber(e.target.value, 2, 6, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.contingency"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.contingency"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.capitalisedCost">
            <Form.Label>Capitalized cost (%)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsPv.capitalisedCost,
                2,
                6,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    capitalisedCost: formatNumber(e.target.value, 2, 6, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.capitalisedCost"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.capitalisedCost"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.capitalisedInterest"
          >
            <Form.Label>Capitalized interest (%)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsPv.capitalisedInterest,
                2,
                6,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    capitalisedInterest: formatNumber(
                      e.target.value,
                      2,
                      6,
                      false
                    ),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.capitalisedInterest"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.capitalisedInterest"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.replacementCapexYear"
          >
            <Form.Label>PV replacement (years)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsPv.replacementCapexYear,
                0,
                0,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    replacementCapexYear: formatNumber(
                      e.target.value,
                      0,
                      0,
                      false
                    ),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.replacementCapexYear"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.replacementCapexYear"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.replacementCapexRate"
          >
            <Form.Label>PV replacement rate (%)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsPv.replacementCapexRate,
                2,
                6,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsPv: {
                    ...site.costInputsPv,
                    replacementCapexRate: formatNumber(
                      e.target.value,
                      2,
                      6,
                      false
                    ),
                  },
                })
              }
              isInvalid={!!errors["costInputsPv.replacementCapexRate"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.replacementCapexRate"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="energyPriceInflationRate">
            <Form.Label>Energy inflation (%)</Form.Label>
            <Form.Control
              type="text"
              value={site.energyPriceInflationRate}
              onChange={(e) =>
                setSite({ energyPriceInflationRate: e.target.value })
              }
              isInvalid={!!errors.energyPriceInflationRate}
            />
            <Form.Control.Feedback type="invalid">
              {errors.energyPriceInflationRate}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {!isBulkEdit && (
          <>
            {!displayCostInflationRates && showCostInflationRatesLink}
            {displayCostInflationRates && costInflationRatesView}
          </>
        )}
      </Row>
    </Card>
  );
};

export default Cost;
