import { useOrganisationList } from "@inrange/building-manager-api-client";
import { formatPercentage } from "@inrange/theme-components/formatting";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import {
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { useEffect, useState } from "react";
import { currencyValue, parseAsNumber, roundToDp } from "./utils";

const FinancialPerformance = ({
  site,
  setSite,
  isOwnerOccupied,
  isBulkEdit,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  isOwnerOccupied: boolean;
  isBulkEdit?: boolean;
}) => {
  const [displayTerms, setDisplayTerms] = useState(false);
  const { errors, siteCalculations, setClientsideValidations } = useContextTS(
    SiteCalculationsContext
  );
  const { fetchOrganisations } = useOrganisationList();
  const orgsById = (fetchOrganisations.data?.organisations || []).reduce(
    (
      acc: { [key: string]: { minIRR?: number } },
      org: { id: string; minIRR?: number }
    ) => {
      acc[org.id] = org;
      return acc;
    },
    {}
  );
  const minIRR = (site.siteOwnerships || [])
    .filter(
      (so) =>
        (so.ownership === "landlord" || so.ownership === "ownerOccupier") &&
        orgsById[so.orgID] !== undefined &&
        orgsById[so.orgID].minIRR !== undefined
    )
    .map((so) => orgsById[so.orgID].minIRR)
    .reduce((acc, minIRR) => {
      if (acc === undefined) {
        return minIRR;
      }
      // If there are multiple minIRR values, return the highest one
      return minIRR > acc ? minIRR : acc;
    }, undefined);
  const ownershipKey = isOwnerOccupied ? "ownerOccupier" : "landlord";
  const isIRROutOfBounds =
    parseAsNumber(site.installedCapacity) > 0 &&
    minIRR &&
    (!siteCalculations.financialModels?.[ownershipKey]?.license?.irr ||
      siteCalculations.financialModels?.[ownershipKey]?.license?.irr < minIRR);
  useEffect(() => {
    setClientsideValidations((prev) => ({
      ...prev,
      isIRROutOfBounds: isIRROutOfBounds,
    }));
  }, [isIRROutOfBounds, setClientsideValidations]);

  const showTermsLink = (
    <Col style={{ paddingTop: "40px" }}>
      <div
        style={{
          textAlign: "center",
          color: "rgb(13, 110, 253)",
        }}
      >
        <a onClick={() => setDisplayTerms(true)}>Show terms</a>
      </div>
    </Col>
  );

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Financial performance</strong>
      </Form.Label>
      {!isBulkEdit && (
        <>
          <Row>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="financialModelIrr">
                <Form.Label>Customer IRR (%)</Form.Label>
                <Form.Control
                  disabled
                  value={
                    siteCalculations.financialModels?.[ownershipKey]?.license
                      ?.irr || "N/A"
                  }
                  isInvalid={isIRROutOfBounds}
                  data-testid="financial-model-irr"
                />
                <Form.Control.Feedback type="invalid">
                  {isIRROutOfBounds
                    ? `IRR must be at least ${formatPercentage(minIRR, 2)}`
                    : undefined}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="paybackMonths">
                <Form.Label>Payback (months)</Form.Label>
                <Form.Control
                  disabled
                  value={
                    siteCalculations.financialModels?.[ownershipKey]?.license
                      ?.paybackMonths || "N/A"
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="licenseRevenueY1">
                <Form.Label>Gross revenue (Y1)</Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.financialModelsInrange
                      ?.grossEnergyRevenue || 0,
                    siteCalculations.currencyCode
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="licenseRevenueY1">
                <Form.Label>Customer license rev (Y1)</Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.financialModels?.[ownershipKey]?.license
                      ?.revenue || 0,
                    siteCalculations.currencyCode
                  )}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="licenseRevenueLT">
                <Form.Label>Customer license rev (LT)</Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.financialModels?.[ownershipKey]?.license
                      ?.lifetimeRevenue || 0,
                    siteCalculations.currencyCode
                  )}
                />
              </Form.Group>
            </Col>
            {ownershipKey === "landlord" && (
              <Col>
                <Form.Group className="mb-3" controlId="tenantSavingsY1">
                  <Form.Label>Tenant savings (Y1)</Form.Label>
                  <Form.Control
                    disabled
                    value={currencyValue(
                      siteCalculations.financialModels?.tenant?.savings || 0,
                      siteCalculations.currencyCode
                    )}
                  />
                </Form.Group>
              </Col>
            )}
            {ownershipKey === "ownerOccupier" && (
              <Col>
                <Form.Group className="mb-3" controlId="ooSavingsY1">
                  <Form.Label>License OO savings (Y1)</Form.Label>
                  <Form.Control
                    disabled
                    value={currencyValue(
                      siteCalculations.financialModels?.[ownershipKey]?.license
                        ?.savings || 0,
                      siteCalculations.currencyCode
                    )}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="leaseReveueY1">
                <Form.Label>Lease revenue (Y1)</Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.financialModels?.[ownershipKey]?.lease
                      ?.revenue || 0,
                    siteCalculations.currencyCode
                  )}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="leaseRevenueLT">
                <Form.Label>Lease revenue (LT)</Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.financialModels?.[ownershipKey]?.lease
                      ?.lifetimeRevenue || 0,
                    siteCalculations.currencyCode
                  )}
                />
              </Form.Group>
            </Col>
            {ownershipKey === "ownerOccupier" && (
              <Col>
                <Form.Group className="mb-3" controlId="leaseOOSavingsY1">
                  <Form.Label>Lease OO savings (Y1)</Form.Label>
                  <Form.Control
                    disabled
                    value={currencyValue(
                      siteCalculations.financialModels?.[ownershipKey]?.lease
                        ?.savings || 0,
                      siteCalculations.currencyCode
                    )}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="irNetRevenueY1License">
                <Form.Label>IR net revenue (Y1, License) </Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.financialModels?.[ownershipKey]?.license
                      ?.inrangeNetRevenue || 0,
                    siteCalculations.currencyCode
                  )}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="irNetRevenueY1Lease">
                <Form.Label>IR net revenue (Y1, Lease) </Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.financialModelsInrange?.lease
                      ?.netRevenue || 0,
                    siteCalculations.currencyCode
                  )}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="irNetRevenueY1Lease">
                <Form.Label>IR arbitrage</Form.Label>
                <Form.Control
                  disabled
                  value={currencyValue(
                    siteCalculations.financialModelsInrange?.installArbitrage ||
                      0,
                    siteCalculations.currencyCode
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="irIrrLease">
                <Form.Label>InRange IRR (Lease)</Form.Label>
                <Form.Control
                  disabled
                  value={
                    siteCalculations.financialModelsInrange?.lease?.irr || "N/A"
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="irDebtPayback">
                <Form.Label>Debt payback (years)</Form.Label>
                <Form.Control
                  disabled
                  value={
                    siteCalculations.financialModelsInrange?.lease
                      ?.paybackMonths
                      ? roundToDp(
                          siteCalculations.financialModelsInrange.lease
                            .paybackMonths / 12,
                          2
                        )
                      : "N/A"
                  }
                />
              </Form.Group>
            </Col>
            {!displayTerms && showTermsLink}
          </Row>
        </>
      )}
      {(displayTerms || isBulkEdit) && (
        <Row className="d-flex flex-wrap">
          <Col sm={4}>
            <Form.Group
              className="mb-3"
              controlId="costInputsInrange.equityFraction"
            >
              <Form.Label>InRange equity (%)</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsInrange!.equityFraction}
                onChange={(e) =>
                  setSite({
                    costInputsInrange: {
                      ...site.costInputsInrange!,
                      equityFraction: e.target.value,
                    },
                  })
                }
                isInvalid={!!errors["costInputsInrange.equityFraction"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsInrange.equityFraction"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group
              className="mb-3"
              controlId="costInputsInrange.targetMinimumDSCR"
            >
              <Form.Label>DSCR Min</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsInrange!.targetMinimumDSCR}
                onChange={(e) =>
                  setSite({
                    costInputsInrange: {
                      ...site.costInputsInrange!,
                      targetMinimumDSCR: e.target.value,
                    },
                  })
                }
                isInvalid={!!errors["costInputsInrange.targetMinimumDSCR"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsInrange.targetMinimumDSCR"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group
              className="mb-3"
              controlId="costInputsInrange.targetMinimumAnnualDSCR"
            >
              <Form.Label>DSCR Annual Target</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsInrange!.targetMinimumAnnualDSCR}
                onChange={(e) =>
                  setSite({
                    costInputsInrange: {
                      ...site.costInputsInrange!,
                      targetMinimumAnnualDSCR: e.target.value,
                    },
                  })
                }
                isInvalid={
                  !!errors["costInputsInrange.targetMinimumAnnualDSCR"]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsInrange.targetMinimumAnnualDSCR"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group
              className="mb-3"
              controlId="costInputsInrange.interestRateConstruction"
            >
              <Form.Label>Interest constr (%)</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsInrange!.interestRateConstruction}
                onChange={(e) =>
                  setSite({
                    costInputsInrange: {
                      ...site.costInputsInrange!,
                      interestRateConstruction: e.target.value,
                    },
                  })
                }
                isInvalid={
                  !!errors["costInputsInrange.interestRateConstruction"]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsInrange.interestRateConstruction"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group
              className="mb-3"
              controlId="costInputsInrange.interestRateOperation"
            >
              <Form.Label>Interest oper (%)</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsInrange!.interestRateOperation}
                onChange={(e) =>
                  setSite({
                    costInputsInrange: {
                      ...site.costInputsInrange!,
                      interestRateOperation: e.target.value,
                    },
                  })
                }
                isInvalid={!!errors["costInputsInrange.interestRateOperation"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsInrange.interestRateOperation"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group
              className="mb-3"
              controlId="costInputsInrange.constructionMonths"
            >
              <Form.Label>Const. period (months)</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsInrange!.constructionMonths}
                onChange={(e) =>
                  setSite({
                    costInputsInrange: {
                      ...site.costInputsInrange!,
                      constructionMonths: e.target.value,
                    },
                  })
                }
                isInvalid={!!errors["costInputsInrange.constructionMonths"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsInrange.constructionMonths"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="costInputsInrange.vatRate">
              <Form.Label>VAT rate (%)</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsInrange!.vatRate}
                onChange={(e) =>
                  setSite({
                    costInputsInrange: {
                      ...site.costInputsInrange!,
                      vatRate: e.target.value,
                    },
                  })
                }
                isInvalid={!!errors["costInputsInrange.vatRate"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsInrange.vatRate"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group
              className="mb-3"
              controlId="costInputsInrange.corporateTaxRate"
            >
              <Form.Label>Corporation tax rate (%)</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsInrange!.corporateTaxRate}
                onChange={(e) =>
                  setSite({
                    costInputsInrange: {
                      ...site.costInputsInrange!,
                      corporateTaxRate: e.target.value,
                    },
                  })
                }
                isInvalid={!!errors["costInputsInrange.corporateTaxRate"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsInrange.corporateTaxRate"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default FinancialPerformance;
