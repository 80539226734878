import { BatterySpec } from "@inrange/building-manager-api-client/models-site";
import { GBP_TO_EUR } from "@inrange/theme-components/formatting";
import { roundToDp } from "./utils";

const convertToCurrency = (
  value: number,
  sourceCurrencyCode: string,
  targetCurrencyCode: string
): number => {
  if (sourceCurrencyCode === targetCurrencyCode) {
    return value;
  }
  if (sourceCurrencyCode === "GBP" && targetCurrencyCode === "EUR") {
    return roundToDp(value * GBP_TO_EUR, 2);
  }
  if (sourceCurrencyCode === "EUR" && targetCurrencyCode === "GBP") {
    return roundToDp(value / GBP_TO_EUR, 2);
  }
  throw new Error(`Unknown currency: ${sourceCurrencyCode}`);
};

export const quotedCost = (
  knownBatteries: Record<string, BatterySpec>,
  modelName: string,
  count: number,
  currencyCode: string
): number => {
  const batterySpec = knownBatteries[modelName];
  const quotedCosts = batterySpec.quotedCosts || [];
  let selectedQuote = quotedCosts[0];
  for (const quote of quotedCosts) {
    if (quote.count >= selectedQuote.count && quote.count <= count) {
      selectedQuote = quote;
    }
  }
  return convertToCurrency(
    selectedQuote.price * (count / selectedQuote.count),
    selectedQuote.currency,
    currencyCode
  );
};

export const estimatedInstallationCost = (
  hardwareCost: number,
  hardwareCurrencyCode: string,
  currencyCode: string
): number => {
  // Hardware cost is 80% of total
  // Install cost is 20% of total
  return convertToCurrency(
    (2 / 8) * hardwareCost,
    hardwareCurrencyCode,
    currencyCode
  );
};

export const quotedInstallationCost = (
  knownBatteries: Record<string, BatterySpec>,
  modelName: string,
  count: number,
  hardwareCost: number,
  hardwareCurrencyCode: string,
  currencyCode: string
): number => {
  const batterySpec = knownBatteries[modelName];
  const quotedCosts = batterySpec.quotedCosts || [];
  if (quotedCosts.length === 0) {
    return estimatedInstallationCost(
      hardwareCost,
      hardwareCurrencyCode,
      currencyCode
    );
  }
  let selectedQuote = quotedCosts[0];
  for (const quote of quotedCosts) {
    if (quote.count >= selectedQuote.count && quote.count <= count) {
      selectedQuote = quote;
    }
  }
  if (
    selectedQuote.installationCost === undefined ||
    selectedQuote.installationCost === null
  ) {
    return estimatedInstallationCost(
      hardwareCost,
      hardwareCurrencyCode,
      currencyCode
    );
  }
  return convertToCurrency(
    selectedQuote.installationCost * (count / selectedQuote.count),
    selectedQuote.currency,
    currencyCode
  );
};
