import {
  AllowStringForNumbers,
  CostInputsInrange,
  CostInputsPv,
  RecursivePartial,
  Site,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsProvider,
  SitePreview,
} from "@inrange/shared-components";
import { Modal, ModalView } from "@inrange/theme-components";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Cost from "../site/siteSections/Cost";

const BulkEditCosts = ({
  selectedSites,
  onSitesUpdate,
  onClose,
}: {
  selectedSites: Set<string>;
  onSitesUpdate: (payload: any) => void;
  onClose: () => void;
}) => {
  const [site, setSite] = useState<
    RecursivePartial<
      AllowStringForNumbers<{
        id: string;
        costInputsPv: CostInputsPv;
        energyPriceInflationRate: number;
        costInputsInrange: CostInputsInrange;
      }>
    >
  >({
    id: "bulk-preview",
    costInputsPv: {
      enablingWorksCosts: undefined,
      wiringCosts: undefined,
      hardwareCostPerKWp: undefined,
      installationCostPerKWp: undefined,
      maintenanceCostPerKWp: undefined,
      insuranceCostPerKWp: undefined,
      flatAnnualCost: undefined,
      roofRentPerM2: undefined,
      roofRentBtm: undefined,
      roofRentExport: undefined,
      contingency: undefined,
      capitalisedCost: undefined,
      capitalisedInterest: undefined,
      replacementCapexYear: undefined,
      replacementCapexRate: undefined,
    },
    costInputsInrange: {
      epcCostPerKWp: undefined,
      constructionMonths: undefined,
      equityFraction: undefined,
      interestRateConstruction: undefined,
      interestRateOperation: undefined,
      vatRate: undefined,
      corporateTaxRate: undefined,
      targetMinimumAnnualDSCR: undefined,
      targetMinimumDSCR: undefined,
    },
    energyPriceInflationRate: undefined,
  });
  return (
    <Modal>
      <ModalView
        title={`Edit costs for ${selectedSites.size} site${selectedSites.size === 1 ? "" : "s"}`}
        fontWeight="500"
        fontSize="20px"
        width="800px"
        titlePadding="0 0 10px 0"
      >
        <SiteCalculationsProvider
          site={site as unknown as Partial<Site>}
          setSaveDisabled={() => {}}
          app="admin"
          isBulkEdit={true}
        >
          <SitePreview
            previewSite={site as unknown as Partial<Site>}
            originalSite={{}}
          >
            <Cost
              site={site as unknown as SiteAllowStringValues}
              setSite={(update) => {
                setSite((prevSite) => ({ ...prevSite, ...update }));
              }}
              isBulkEdit={true}
            />
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Button variant="inrangesecondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  // Or all the values with undefined to remove blank strings
                  onSitesUpdate({
                    site: {
                      costInputsPv: {
                        enablingWorksCosts:
                          site.costInputsPv.enablingWorksCosts || undefined,
                        hardwareCostPerKWp:
                          site.costInputsPv.hardwareCostPerKWp || undefined,
                        installationCostPerKWp:
                          site.costInputsPv.installationCostPerKWp || undefined,
                        maintenanceCostPerKWp:
                          site.costInputsPv.maintenanceCostPerKWp || undefined,
                        insuranceCostPerKWp:
                          site.costInputsPv.insuranceCostPerKWp || undefined,
                        flatAnnualCost:
                          site.costInputsPv.flatAnnualCost || undefined,
                        roofRentPerM2:
                          site.costInputsPv.roofRentPerM2 || undefined,
                        roofRentBtm: site.costInputsPv.roofRentBtm || undefined,
                        roofRentExport:
                          site.costInputsPv.roofRentExport || undefined,
                        contingency: site.costInputsPv.contingency || undefined,
                        capitalisedCost:
                          site.costInputsPv.capitalisedCost || undefined,
                        capitalisedInterest:
                          site.costInputsPv.capitalisedInterest || undefined,
                        replacementCapexYear:
                          site.costInputsPv.replacementCapexYear || undefined,
                        replacementCapexRate:
                          site.costInputsPv.replacementCapexRate || undefined,
                      },
                      costInputsInrange: {
                        ...site.costInputsInrange,
                        epcCostPerKWp:
                          site.costInputsInrange.epcCostPerKWp || undefined,
                      },
                      energyPriceInflationRate:
                        site.energyPriceInflationRate || undefined,
                    },
                  });
                }}
              >
                Edit costs
              </Button>
            </div>
          </SitePreview>
        </SiteCalculationsProvider>
      </ModalView>
    </Modal>
  );
};

export default BulkEditCosts;
