import { Site } from "@inrange/building-manager-api-client/models-site";
import hash from "object-hash";
import React, { ReactNode, useEffect, useState } from "react";
import { SiteCalculationsContext } from "./SiteCalculationsContext";
import { buildSitePayload } from "./buildSitePayload";
import { useContextTS } from "./context-utils";

interface SitePreviewProps {
  children: ReactNode;
  previewSite: Partial<Site>;
  originalSite: Partial<Site>;
}

const SitePreview: React.FC<SitePreviewProps> = ({
  children,
  previewSite,
  originalSite,
}) => {
  const { updateCalculations } = useContextTS(SiteCalculationsContext);
  const originalSiteHash = hash(buildSitePayload(originalSite));

  const [previousSiteHash, setPreviousSiteHash] =
    useState<string>(originalSiteHash);

  useEffect(() => {
    if (Object.keys(previewSite).length === 0) return;
    const previewSitePayload = buildSitePayload(previewSite);
    const updatedSiteHash = hash(previewSitePayload);
    if (previousSiteHash === updatedSiteHash) return;

    // @ts-ignore
    const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

    // Debounce so we don't recalculate immediately on keypress
    const debounceWaitMillis = testMode ? 0 : 300;
    const timeoutId = setTimeout(() => {
      setPreviousSiteHash(updatedSiteHash);
      updateCalculations(previewSitePayload);
    }, debounceWaitMillis);

    return () => clearTimeout(timeoutId);
  }, [previewSite, originalSiteHash, previousSiteHash, updateCalculations]);

  return <>{children}</>;
};

export default SitePreview;
