import {
  AllowStringForNumbers,
  CostInputsInrange,
  RecursivePartial,
  Site,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsProvider,
  SitePreview,
} from "@inrange/shared-components";
import { Modal, ModalView } from "@inrange/theme-components";
import { useState } from "react";
import { Button } from "react-bootstrap";
import FinancialPerformance from "../site/siteSections/FinancialPerformance";

const BulkEditFinancialPerformance = ({
  selectedSites,
  onSitesUpdate,
  onClose,
}: {
  selectedSites: Set<string>;
  onSitesUpdate: (payload: any) => void;
  onClose: () => void;
}) => {
  const [site, setSite] = useState<
    RecursivePartial<
      AllowStringForNumbers<{
        id: string;
        costInputsInrange: CostInputsInrange;
      }>
    >
  >({
    id: "bulk-preview",
    costInputsInrange: {
      epcCostPerKWp: undefined,
      constructionMonths: undefined,
      equityFraction: undefined,
      interestRateConstruction: undefined,
      interestRateOperation: undefined,
      vatRate: undefined,
      corporateTaxRate: undefined,
      targetMinimumAnnualDSCR: undefined,
      targetMinimumDSCR: undefined,
    },
  });
  return (
    <Modal>
      <ModalView
        title={`Edit financial performance for ${selectedSites.size} site${selectedSites.size === 1 ? "" : "s"}`}
        fontWeight="500"
        fontSize="20px"
        width="800px"
        titlePadding="0 0 10px 0"
      >
        <SiteCalculationsProvider
          site={site as unknown as Partial<Site>}
          setSaveDisabled={() => {}}
          app="admin"
          isBulkEdit={true}
        >
          <SitePreview
            previewSite={site as unknown as Partial<Site>}
            originalSite={{}}
          >
            <FinancialPerformance
              site={site as unknown as SiteAllowStringValues}
              setSite={(update) => {
                setSite((prevSite) => ({ ...prevSite, ...update }));
              }}
              isOwnerOccupied={false}
              isBulkEdit={true}
            />
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Button variant="inrangesecondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  // Or all the values with undefined to remove blank strings
                  onSitesUpdate({
                    site: {
                      costInputsInrange: {
                        epcCostPerKWp:
                          site.costInputsInrange.epcCostPerKWp || undefined,
                        constructionMonths:
                          site.costInputsInrange.constructionMonths ||
                          undefined,
                        equityFraction:
                          site.costInputsInrange.equityFraction || undefined,
                        interestRateConstruction:
                          site.costInputsInrange.interestRateConstruction ||
                          undefined,
                        interestRateOperation:
                          site.costInputsInrange.interestRateOperation ||
                          undefined,
                        vatRate: site.costInputsInrange.vatRate || undefined,
                        corporateTaxRate:
                          site.costInputsInrange.corporateTaxRate || undefined,
                        targetMinimumAnnualDSCR:
                          site.costInputsInrange.targetMinimumAnnualDSCR ||
                          undefined,
                        targetMinimumDSCR:
                          site.costInputsInrange.targetMinimumDSCR || undefined,
                      },
                    },
                  });
                }}
              >
                Edit financial performance
              </Button>
            </div>
          </SitePreview>
        </SiteCalculationsProvider>
      </ModalView>
    </Modal>
  );
};

export default BulkEditFinancialPerformance;
