import {
  extraErrorDataIntegration,
  httpClientIntegration,
} from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";

import {
  KINDE_ENVIRONMENT,
  KindeAuthenticatedUI,
  KindeRedirectProvider,
} from "@inrange/auth";

import { GlobalStyle, ThemeProvider } from "@inrange/theme-components/styles";
import { QueryClientProvider } from "react-query";
import AppRoutes from "./AppRoutes";
import { UserProvider } from "./auth/UserContext";
import queryClient from "./queryClient";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://f9bb24586c704c40871064c57bf5c477@o1336164.ingest.sentry.io/4505561257738240",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      httpClientIntegration({
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
        failedRequestStatusCodes: [
          // Don't alert on 400 Bad Request in Customer as we expect this from:
          // - preview requests which contain an invalid value
          // - token requests to the Kinde endpoint when the user doesn't have a token
          [401, 499],
          [500, 502],
          // Don't alert on 503 Service Unavailable as we get that when battery requests timeout
          [504, 599],
        ],
      }),
      extraErrorDataIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_BRANCH_NAME,
  });
}

const isDangerouslyUseLocalStorage =
  !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
if (isDangerouslyUseLocalStorage) {
  console.log(
    "kinde_refresh_token:",
    window.localStorage.getItem("kinde_refresh_token")
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <KindeRedirectProvider>
    <KindeAuthenticatedUI
      kindeClientId={KINDE_ENVIRONMENT.KINDE_ADMIN_APP_CLIENT_ID}
      isDangerouslyUseLocalStorage={isDangerouslyUseLocalStorage}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <GlobalStyle />
          <UserProvider>
            <AppRoutes />
          </UserProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </KindeAuthenticatedUI>
  </KindeRedirectProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
