import { useSite } from "@inrange/building-manager-api-client";
import {
  AggregateEnergyFlow,
  BatterySpec,
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import { sortBy } from "@inrange/calculations/utils";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { currencySymbol } from "@inrange/theme-components/formatting";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { quotedCost, quotedInstallationCost } from "./battery-utils";
import BatteryTooltip from "./BatteryTooltip";
import { formatNumber, parseAsNumber, stripBatteryQuotes } from "./utils";

const BatterySettings = ({
  site,
  setSite,
  currencyCode,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  currencyCode: string;
}) => {
  const { errors, siteCalculations } = useContextTS(SiteCalculationsContext);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const { fetchSiteValues } = useSite({
    app: "admin",
    enableNonEssentialQueries: true,
  });

  const knownBatteries: Record<string, BatterySpec> = fetchSiteValues.data
    ? fetchSiteValues.data.options.batteries[currencyCode]
    : {};
  const isKnownModel = !!(
    site.costInputsBattery.batterySpec &&
    site.costInputsBattery.batterySpec.modelName &&
    !!knownBatteries[site.costInputsBattery.batterySpec.modelName]
  );

  return (
    <>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="pvPanel">
            <Form.Label>Model</Form.Label>
            <Form.Select
              value={site.costInputsBattery.batterySpec?.modelName || ""}
              onChange={(e) => {
                const batteryModelName = e.target.value;
                if (batteryModelName === "") {
                  setSite({
                    costInputsBattery: {
                      ...site.costInputsBattery,
                      batterySpec: {
                        capacityMwh: "",
                        powerMw: "",
                        ...site.costInputsBattery.batterySpec,
                        modelName: null,
                        count: 1,
                        hardwareCost: 0,
                      },
                    },
                  });
                } else {
                  const batterySpec = stripBatteryQuotes(
                    knownBatteries[batteryModelName]
                  );
                  const count =
                    site.costInputsBattery.batterySpec?.count === 0
                      ? 1
                      : site.costInputsBattery.batterySpec?.count || 1;
                  setSite({
                    costInputsBattery: {
                      ...site.costInputsBattery,
                      batterySpec: {
                        ...batterySpec,
                        count,
                        hardwareCost: quotedCost(
                          knownBatteries,
                          batteryModelName,
                          parseFloat(count as string),
                          currencyCode
                        ),
                      },
                      installationCost: quotedInstallationCost(
                        knownBatteries,
                        batteryModelName,
                        parseFloat(count as string),
                        quotedCost(
                          knownBatteries,
                          batteryModelName,
                          parseFloat(count as string),
                          currencyCode
                        ),
                        currencyCode,
                        currencyCode
                      ),
                    },
                  });
                }
              }}
            >
              <option value="">No set model</option>
              {Object.entries(knownBatteries)
                .sort(sortBy(([_, spec]) => spec.modelName))
                .map(([batteryModelName, _], index) => (
                  <option key={index}>{batteryModelName}</option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Num batteries</Form.Label>
            <Form.Control
              type="text"
              value={
                isKnownModel ? site.costInputsBattery.batterySpec?.count : ""
              }
              disabled={!isKnownModel}
              onChange={(e) => {
                setSite({
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    batterySpec: {
                      capacityMwh: "",
                      powerMw: "",
                      ...site.costInputsBattery.batterySpec,
                      count: e.target.value,
                      ...(isNaN(parseFloat(e.target.value))
                        ? {}
                        : {
                            hardwareCost: quotedCost(
                              knownBatteries,
                              site.costInputsBattery.batterySpec!.modelName!,
                              parseFloat(e.target.value),
                              currencyCode
                            ),
                          }),
                    },
                    ...(isNaN(parseFloat(e.target.value))
                      ? {}
                      : {
                          installationCost: quotedInstallationCost(
                            knownBatteries,
                            site.costInputsBattery.batterySpec!.modelName!,
                            parseFloat(e.target.value),
                            quotedCost(
                              knownBatteries,
                              site.costInputsBattery.batterySpec!.modelName!,
                              parseFloat(e.target.value),
                              currencyCode
                            ),
                            currencyCode,
                            currencyCode
                          ),
                        }),
                  },
                });
              }}
              isInvalid={!!errors["costInputsBattery.batterySpec.count"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsBattery.batterySpec.count"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Power (MW)</Form.Label>
            <Form.Control
              type="text"
              value={
                site.costInputsBattery.batterySpec
                  ? formatNumber(
                      isKnownModel
                        ? parseAsNumber(
                            site.costInputsBattery.batterySpec.powerMw
                          ) *
                            parseAsNumber(
                              site.costInputsBattery.batterySpec.count
                            )
                        : site.costInputsBattery.batterySpec.powerMw,
                      0,
                      6,
                      false
                    )
                  : ""
              }
              disabled={isKnownModel}
              onChange={(e) => {
                setSite({
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    batterySpec: {
                      capacityMwh: "",
                      count: 1,
                      ...site.costInputsBattery.batterySpec,
                      powerMw: formatNumber(e.target.value, 1, 6, false),
                    },
                  },
                });
              }}
              isInvalid={!!errors["costInputsBattery.batterySpec.powerMw"]}
              data-testid={"batteryPower"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsBattery.batterySpec.powerMw"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>
              {site.costInputsBattery.batterySpec ? (
                <BatteryTooltip
                  batterySpec={site.costInputsBattery.batterySpec}
                  energyFlowAnnual={
                    siteCalculations.energyFlowAnnual as unknown as AggregateEnergyFlow
                  }
                  energyFlowMonthly={
                    siteCalculations.energyFlowMonthly as unknown as {
                      [month: number]: AggregateEnergyFlow;
                    }
                  }
                >
                  <span
                    style={{
                      textDecoration: "dotted underline 1px",
                    }}
                  >
                    Capacity (MWh)
                  </span>
                </BatteryTooltip>
              ) : (
                <>Capacity (MWh)</>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              value={
                site.costInputsBattery.batterySpec
                  ? formatNumber(
                      isKnownModel
                        ? parseAsNumber(
                            site.costInputsBattery.batterySpec.capacityMwh
                          ) *
                            parseAsNumber(
                              site.costInputsBattery.batterySpec.count
                            )
                        : site.costInputsBattery.batterySpec.capacityMwh,
                      0,
                      6,
                      false
                    )
                  : ""
              }
              disabled={isKnownModel}
              onChange={(e) => {
                setSite({
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    batterySpec: {
                      powerMw: "",
                      count: 1,
                      ...site.costInputsBattery.batterySpec,
                      capacityMwh: formatNumber(e.target.value, 1, 6, false),
                    },
                  },
                });
              }}
              isInvalid={!!errors["costInputsBattery.batterySpec.capacityMwh"]}
              data-testid={"batteryCapacity"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsBattery.batterySpec.capacityMwh"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Cost ({currencySymbol(currencyCode)})</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsBattery.batterySpec?.hardwareCost || "",
                2,
                6,
                false
              )}
              onChange={(e) => {
                setSite({
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    batterySpec: {
                      capacityMwh: "",
                      powerMw: "",
                      ...site.costInputsBattery.batterySpec,
                      hardwareCost: formatNumber(e.target.value, 2, 6, false),
                    },
                  },
                });
              }}
              isInvalid={!!errors["costInputsBattery.batterySpec.hardwareCost"]}
              data-testid={"batteryCost"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsBattery.batterySpec.hardwareCost"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="batteryInstallationCost">
            <Form.Label>
              Installation cost ({currencySymbol(currencyCode)})
            </Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsBattery.installationCost || "",
                2,
                6,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    installationCost: formatNumber(e.target.value, 2, 6, false),
                  },
                })
              }
              isInvalid={!!errors["costInputsBattery.installationCost"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsBattery.installationCost"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3} className="d-flex align-items-center">
          {!showAdvanced && (
            <a
              onClick={() => setShowAdvanced(true)}
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              Advanced settings
            </a>
          )}
        </Col>
        <Col
          sm={3}
          className="d-flex align-items-center justify-content-end pe-4"
        >
          {(!!site.costInputsBattery.batterySpec ||
            !!site.costInputsBattery.installationCost) && (
            <a
              onClick={() =>
                setSite({
                  // We set nulls here rather than undefined so that they get sent to the backend
                  batteryEnergyFlowPayloadHash: null,
                  batteryEnergyFlowFileVersionId: null,
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    batterySpec: undefined,
                    installationCost: 0,
                  },
                })
              }
              className="text-danger"
              style={{
                cursor: "pointer",
                textDecoration: "none",
              }}
              data-testid="remove-battery"
            >
              Remove battery
            </a>
          )}
        </Col>
      </Row>
      {showAdvanced && (
        <Row className="d-flex flex-wrap">
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="batteryModelNotes">
              <Form.Label>Model notes</Form.Label>
              <Form.Control
                type="text"
                value={site.costInputsBattery.batterySpec?.modelNotes || ""}
                onChange={(e) =>
                  setSite({
                    costInputsBattery: {
                      ...site.costInputsBattery,
                      batterySpec: {
                        capacityMwh: "",
                        powerMw: "",
                        ...site.costInputsBattery.batterySpec,
                        modelNotes: e.target.value,
                      },
                    },
                  })
                }
                isInvalid={!!errors["costInputsBattery.batterySpec.modelNotes"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsBattery.batterySpec.modelNotes"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="batteryWarrantyYears">
              <Form.Label>Replacement (years)</Form.Label>
              <Form.Control
                type="text"
                value={
                  site.costInputsBattery.batterySpec?.warranty?.periodYears ||
                  ""
                }
                onChange={(e) =>
                  setSite({
                    costInputsBattery: {
                      ...site.costInputsBattery,
                      batterySpec: {
                        capacityMwh: "",
                        powerMw: "",
                        ...site.costInputsBattery.batterySpec,
                        warranty: {
                          ...site.costInputsBattery.batterySpec?.warranty,
                          periodYears: e.target.value,
                        },
                      },
                    },
                  })
                }
                isInvalid={
                  !!errors["costInputsBattery.batterySpec.warranty.periodYears"]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsBattery.batterySpec.warranty.periodYears"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="batteryWarrantyCycles">
              <Form.Label>Replacement (cycles)</Form.Label>
              <Form.Control
                type="text"
                value={
                  site.costInputsBattery.batterySpec?.warranty?.periodCycles ||
                  ""
                }
                onChange={(e) =>
                  setSite({
                    costInputsBattery: {
                      ...site.costInputsBattery,
                      batterySpec: {
                        capacityMwh: "",
                        powerMw: "",
                        ...site.costInputsBattery.batterySpec,
                        warranty: {
                          ...site.costInputsBattery.batterySpec?.warranty,
                          periodCycles: e.target.value,
                        },
                      },
                    },
                  })
                }
                isInvalid={
                  !!errors[
                    "costInputsBattery.batterySpec.warranty.periodCycles"
                  ]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsBattery.batterySpec.warranty.periodCycles"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="batteryWarrantyCapacity">
              <Form.Label>End of warranty capacity (%)</Form.Label>
              <Form.Control
                type="text"
                value={formatNumber(
                  site.costInputsBattery.batterySpec?.warranty?.capacity || "",
                  1,
                  6,
                  false
                )}
                onChange={(e) =>
                  setSite({
                    costInputsBattery: {
                      ...site.costInputsBattery,
                      batterySpec: {
                        capacityMwh: "",
                        powerMw: "",
                        ...site.costInputsBattery.batterySpec,
                        warranty: {
                          ...site.costInputsBattery.batterySpec?.warranty,
                          capacity: formatNumber(e.target.value, 1, 6, false),
                        },
                      },
                    },
                  })
                }
                isInvalid={
                  !!errors["costInputsBattery.batterySpec.warranty.capacity"]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsBattery.batterySpec.warranty.capacity"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3">
              <Form.Label>Depth of discharge</Form.Label>
              <Form.Control
                type="text"
                value={formatNumber(
                  site.costInputsBattery.batterySpec?.depthOfDischarge || "",
                  1,
                  6,
                  false
                )}
                onChange={(e) =>
                  setSite({
                    costInputsBattery: {
                      ...site.costInputsBattery,
                      batterySpec: {
                        capacityMwh: "",
                        powerMw: "",
                        ...site.costInputsBattery.batterySpec,
                        depthOfDischarge: formatNumber(
                          e.target.value,
                          1,
                          6,
                          false
                        ),
                      },
                    },
                  })
                }
                isInvalid={
                  !!errors["costInputsBattery.batterySpec.depthOfDischarge"]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsBattery.batterySpec.depthOfDischarge"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3">
              <Form.Label>Round trip efficiency</Form.Label>
              <Form.Control
                type="text"
                value={formatNumber(
                  site.costInputsBattery.batterySpec?.roundTripEfficiency || "",
                  1,
                  6,
                  false
                )}
                onChange={(e) =>
                  setSite({
                    costInputsBattery: {
                      ...site.costInputsBattery,
                      batterySpec: {
                        capacityMwh: "",
                        powerMw: "",
                        ...site.costInputsBattery.batterySpec,
                        roundTripEfficiency: formatNumber(
                          e.target.value,
                          1,
                          6,
                          false
                        ),
                      },
                    },
                  })
                }
                isInvalid={
                  !!errors["costInputsBattery.batterySpec.roundTripEfficiency"]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["costInputsBattery.batterySpec.roundTripEfficiency"]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BatterySettings;
