import { Link } from "react-router-dom";
import styled from "styled-components";

const SimpleButton = ({
  label,
  background,
  fontColor,
  borderColor,
  ...props
}: {
  label: string;
  background?: string;
  fontColor?: string;
  borderColor?: string;
  to?: string;
  download?: string;
  href?: string;
  fileName?: string;
  [key: string]: any;
}) => {
  if (props.to !== undefined) {
    return (
      <ButtonLink
        {...props}
        to={props.to}
        background={background}
        $fontColor={fontColor}
      >
        <span>{label}</span>
      </ButtonLink>
    );
  }
  if (props.download && props.href) {
    return (
      <Button
        {...props}
        background={background}
        $fontColor={fontColor}
        borderColor={borderColor}
        href={props.href}
        download={props.fileName}
      >
        <span>{label}</span>
      </Button>
    );
  }
  return (
    <Button
      {...props}
      background={background}
      $fontColor={fontColor}
      borderColor={borderColor}
    >
      <span>{label}</span>
    </Button>
  );
};

export const Button = styled.button<{
  background?: string;
  $fontColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  fontWeight?: number;
  fontSize?: string;
  href?: string;
  download?: string;
  padding?: string;
}>`
  display: flex;
  align-items: center;
  background: ${({ background, theme }) => background || theme.colors.primary};
  color: ${({ $fontColor }) => $fontColor || "white"};
  border: ${({ borderColor }) =>
    borderColor ? `solid ${borderColor}` : `none`};
  border-radius: 8px;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "44px"};
  padding: ${({ padding }) => padding || "16px"};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  line-height: 20px;
  span {
    user-select: none;
    width: 100%;
    text-align: center;
  }
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
    }}
`;

const ButtonLink = styled(Link)<{
  background?: string;
  $fontColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  fontWeight?: number;
  fontSize?: string;
  disabled?: boolean;
  padding?: string;
}>`
  text-decoration: none;
  display: flex;
  align-items: center;
  background: ${({ background, theme }) => background || theme.colors.primary};
  color: ${({ $fontColor }) => $fontColor || "white"};
  border: ${({ borderColor }) =>
    borderColor ? `solid ${borderColor}` : `none`};
  border-radius: 8px;
  height: ${({ height }) => height || "44px"};
  width: ${({ width }) => width || "auto"};
  padding: ${({ padding }) => padding || "16px"};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  line-height: 20px;
  span {
    user-select: none;
    width: 100%;
    text-align: center;
  }
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
    }}
`;

export default SimpleButton;
